define("ember-svg-jar/inlined/icon-file-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm6.851 28v-5a1 1 0 011-1h1.42c.545 0 1.046.087 1.501.26s.839.414 1.15.721a3.2 3.2 0 01.724 1.112c.172.433.257.906.257 1.419s-.082.985-.247 1.414c-.166.43-.4.798-.703 1.105a3.1 3.1 0 01-1.135.714 4.3 4.3 0 01-1.518.255h-1.449a1 1 0 01-1-1zm1.621-.467h.727c.237 0 .46-.034.669-.101a1.81 1.81 0 00.553-.287 1.912 1.912 0 00.678-.989c.061-.205.092-.42.092-.644 0-.385-.081-.733-.243-1.044a1.81 1.81 0 00-1.638-.996h-.838zm5.394.656V23a1 1 0 011-1h2.997a.736.736 0 110 1.472H18.73v1.573h1.838a.731.731 0 110 1.463H18.73v1.681a.81.81 0 01-1.621 0zm-13.01-.098V23a1 1 0 011-1h1.724c.343 0 .655.04.935.12s.52.194.72.342c.198.147.365.325.5.531.135.207.233.435.296.684.062.248.094.518.094.81 0 .517-.111.955-.332 1.316-.222.36-.53.628-.924.801s-.863.26-1.405.26h-.789v1.227a.91.91 0 11-1.818 0zM5.82 25.5h.905c.386 0 .694-.086.925-.259.23-.173.346-.42.346-.741a.908.908 0 00-.328-.714c-.219-.19-.531-.286-.937-.286h-.91zM5 15a1 1 0 01.995-1h14.01a1 1 0 010 2H5.995A.995.995 0 015 15zm0-5c0-.552.451-1 1.004-1h9.992a1 1 0 110 2H6.004A1 1 0 015 10z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("@ember/string/helpers/loc", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loc = loc;
  _exports.default = void 0;

  function loc(params
  /*, hash*/
  ) {
    var _Ember$String;

    return (_Ember$String = Ember.String).loc.apply(_Ember$String, (0, _toConsumableArray2.default)(params));
  }

  var _default = Ember.Helper.helper(loc);

  _exports.default = _default;
});
define("web-core/types/event-annotations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isUserAnnotations = isUserAnnotations;
  _exports.isBroadcastAnnotations = isBroadcastAnnotations;
  _exports.isEventAnnotations = isEventAnnotations;
  _exports.isAnnotatedContent = isAnnotatedContent;

  function isUserAnnotations(annotations) {
    /* eslint no-prototype-builtins: 0 */
    return !!annotations && annotations.hasOwnProperty('users');
  }

  function isBroadcastAnnotations(annotations) {
    /* eslint no-prototype-builtins: 0 */
    return !!annotations && annotations.hasOwnProperty('broadcast');
  }

  function isEventAnnotations(annotations) {
    /* eslint no-prototype-builtins: 0 */
    return !!annotations && annotations.hasOwnProperty('events');
  }

  function isAnnotatedContent(content) {
    return content.hasOwnProperty('annotations');
  }
});
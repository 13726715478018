define("ember-svg-jar/inlined/icon-tipps", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11.635 9.235c.31 0 .576-.109.8-.328a1.07 1.07 0 00.335-.793c0-.305-.112-.567-.335-.786a1.103 1.103 0 00-.8-.328 1.1 1.1 0 00-.803.328c-.221.219-.332.48-.332.786a1.107 1.107 0 00.564.97c.175.101.366.151.57.151zm-.916 7.8h1.832V10.2h-1.832v6.836z\" fill=\"#B3C0CE\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24",
      "height": "24",
      "width": "24"
    }
  };
  _exports.default = _default;
});
define("web-core/types/event-content/s.message.action.validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ActionValidationResult = void 0;
  var ActionValidationResult;
  _exports.ActionValidationResult = ActionValidationResult;

  (function (ActionValidationResult) {
    ActionValidationResult["SUCCESS"] = "SUCCESS";
    ActionValidationResult["FAILURE"] = "FAILURE";
  })(ActionValidationResult || (_exports.ActionValidationResult = ActionValidationResult = {}));
});
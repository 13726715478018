define("ember-svg-jar/inlined/icon-file-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zM5 15a1 1 0 01.995-1h14.01a1 1 0 010 2H5.995A.995.995 0 015 15zm0-5c0-.552.451-1 1.004-1h9.992a1 1 0 110 2H6.004A1 1 0 015 10zm11.798 12h.12a.645.645 0 01.615.838l-1.707 5.46a1 1 0 01-.955.702h-.018c-.443 0-.83-.3-.939-.73l-.988-3.874-1.037 3.877a.98.98 0 01-.947.727h-.018a1 1 0 01-.954-.702l-1.708-5.46A.645.645 0 018.878 22h.116a1 1 0 01.964.734l.982 3.562.904-3.543a1 1 0 01.97-.753h.177a1 1 0 01.97.759l.88 3.537.994-3.565a1 1 0 01.963-.731z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/utils/make-helper", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = makeHelper;

  function makeHelper(helperFunc) {
    var helper;

    if (Ember.Helper && Ember.Helper.helper) {
      helper = Ember.Helper.helper(function (_ref, options) {
        var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
            assetId = _ref2[0];

        return helperFunc(assetId, options);
      });
    } else {
      helper = Ember.Handlebars.makeBoundHelper(function (assetId, options) {
        return helperFunc(assetId, options.hash || {});
      });
    }

    return helper;
  }
});
define("ember-inflector/lib/helpers/pluralize", ["exports", "@babel/runtime/helpers/esm/construct", "@babel/runtime/helpers/esm/toConsumableArray", "ember-inflector", "ember-inflector/lib/utils/make-helper"], function (_exports, _construct2, _toConsumableArray2, _emberInflector, _makeHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   *
   * If you have Ember Inflector (such as if Ember Data is present),
   * pluralize a word. For example, turn "ox" into "oxen".
   *
   * Example:
   *
   * {{pluralize count myProperty}}
   * {{pluralize 1 "oxen"}}
   * {{pluralize myProperty}}
   * {{pluralize "ox"}}
   *
   * @for Ember.HTMLBars.helpers
   * @method pluralize
   * @param {Number|Property} [count] count of objects
   * @param {String|Property} word word to pluralize
   */
  var _default = (0, _makeHelper.default)(function (params, hash) {
    var fullParams = (0, _construct2.default)(Array, (0, _toConsumableArray2.default)(params));

    if (fullParams.length === 2) {
      fullParams.push({
        withoutCount: hash["without-count"]
      });
    }

    return _emberInflector.pluralize.apply(void 0, (0, _toConsumableArray2.default)(fullParams));
  });

  _exports.default = _default;
});
define("ember-assign-helper/helpers/assign", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assign = assign;
  _exports.default = void 0;

  function assign(params) {
    var _Ember;

    return (_Ember = Ember).assign.apply(_Ember, [{}].concat((0, _toConsumableArray2.default)(params)));
  }

  var _default = Ember.Helper.helper(assign);

  _exports.default = _default;
});
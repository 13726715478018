define("web-core/utils/markup-parser/lib/token/Token", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "@babel/runtime/helpers/esm/defineProperty", "web-core/utils/markup-parser/lib/token/TokenKind"], function (_exports, _classCallCheck2, _defineProperty2, _TokenKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Token = function Token(start, end, id) {
    var kind = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _TokenKind.default.Default;
    var meta = arguments.length > 4 ? arguments[4] : undefined;
    (0, _classCallCheck2.default)(this, Token);
    (0, _defineProperty2.default)(this, "consumed", false);
    this.start = start;
    this.end = end;
    this.id = id;
    this.kind = kind;
    this.meta = meta;
  };

  var _default = Token;
  _exports.default = _default;
});
define("web-core/utils/mimes", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TYPES = _exports.MIME_TYPE_LIST = _exports.TYPES_MIME_LIST = _exports.TYPE = void 0;

  var _TYPES, _TYPES_MIME_LIST;

  /* eslint no-useless-escape: 0 */
  var TYPE;
  _exports.TYPE = TYPE;

  (function (TYPE) {
    TYPE["MS_OFFICE"] = "MS_OFFICE";
    TYPE["LIBRE_OFFICE"] = "LIBRE_OFFICE";
    TYPE["CSV"] = "CSV";
    TYPE["TEXT"] = "TEXT";
    TYPE["MARKDOWN"] = "MARKDOWN";
    TYPE["VIDEO"] = "VIDEO";
    TYPE["IMAGE"] = "IMAGE";
    TYPE["PDF"] = "PDF";
    TYPE["ARCHIVES"] = "ARCHIVES";
    TYPE["AUDIO"] = "AUDIO";
  })(TYPE || (_exports.TYPE = TYPE = {}));

  var TYPES = (_TYPES = {}, (0, _defineProperty2.default)(_TYPES, TYPE.MS_OFFICE, {
    'application/msword': 'doc|dot',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': 'dotx',
    'application/vnd.ms-word.document.macroEnabled.12': 'docm',
    'application/vnd.ms-word.template.macroEnabled.12': 'dotm',
    'application/vnd.ms-powerpoint': 'ppt|pot|pps|ppa',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.template': 'potx',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': 'ppsx',
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': 'ppam',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': 'pptm|potm',
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': 'ppsm'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.LIBRE_OFFICE, {
    'application/vnd.oasis.opendocument.text': 'odt',
    'application/vnd.oasis.opendocument.text-template': 'ott',
    'application/vnd.oasis.opendocument.text-web': 'oth',
    'application/vnd.oasis.opendocument.text-master': 'odm',
    'application/vnd.oasis.opendocument.graphics': 'odg',
    'application/vnd.oasis.opendocument.graphics-template': 'otg',
    'application/vnd.oasis.opendocument.presentation': 'odp',
    'application/vnd.oasis.opendocument.presentation-template': 'otp',
    'application/vnd.oasis.opendocument.chart': 'odc',
    'application/vnd.oasis.opendocument.formula': 'odf',
    'application/vnd.oasis.opendocument.database': 'odb',
    'application/vnd.oasis.opendocument.image': 'odi',
    'application/vnd.openofficeorg.extension': 'oxt'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.CSV, {
    'text/csv': 'csv',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': 'xltx',
    'application/vnd.ms-excel.sheet.macroEnabled.12': 'xlsm',
    'application/vnd.ms-excel.template.macroEnabled.12': 'xltm',
    'application/vnd.ms-excel.addin.macroEnabled.12': 'xlam',
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': 'xlsb',
    'application/vnd.oasis.opendocument.spreadsheet': 'ods',
    'application/vnd.oasis.opendocument.spreadsheet-template': 'ots'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.TEXT, {
    'text/rtf': 'rtf',
    'text/plain': 'txt'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.MARKDOWN, {
    'text/markdown': 'md'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.VIDEO, {
    'video/avi': 'avi|divx',
    'video/msvideo': 'avi',
    'video/x-msvideo': 'avi',
    'video/xmpg2': 'avi',
    'application/x-troff-msvideo': 'avi',
    'video/mpeg': 'mpg|mpeg|mp1|mp2|mp3|m1v|m1a|m2a|mpa|mpv',
    'video/mp4': 'mp4|m4a|m4p|m4b|m4r|m4v',
    'video/ogg': 'ogg|ogv|oga|ogx|ogm|spx|opus',
    'video/quicktime': 'mov|qt',
    'video/webm': 'webm',
    'video/x-matroska': 'mkv|mk3d|mka|mks',
    'video/x-ms-wmv': 'asf|wma|wmv',
    'video/x-flv': 'flv|f4v|f4p|f4a|f4b'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.AUDIO, {
    'audio/flac': 'flac',
    'audio/mpegurl': 'm3u|m3u8',
    'audio/mp4': 'm4a|m4b',
    'audio/mpeg': 'mp4',
    'audio/ogg': 'ogg|opus',
    'audio/x-scpls': 'pls',
    'audio/wav': 'wav'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.IMAGE, {
    'image/png': 'png',
    'image/jpeg': 'jpe?g|jpe|jif|jfif|jfi',
    'image/gif': 'gif',
    'image/tiff': 'tif?f',
    'image/bmp': 'bmp|dib',
    'image/webp': 'webp',
    'image/svg+xml': 'svgz?',
    'image/vnd.adobe.photoshop': 'psd',
    'image/heif': 'heif',
    'image/heif-sequence': 'heif',
    'image/heic': 'heic',
    'image/heic-sequence': 'heic'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.PDF, {
    'application/pdf': 'pdf'
  }), (0, _defineProperty2.default)(_TYPES, TYPE.ARCHIVES, {
    'application/x-tar': 'tar',
    'application/x-bzip2': 'bz2',
    'application/x-bzip': 'bz2',
    'application/x-gzip': 'gz',
    'application/gzip': 'gz',
    'application/x-lzip': 'lz',
    'application/x-lzma': 'lzma',
    'application/x-lzop': 'lzo',
    'application/x-xz': 'xz',
    'application/x-compress': 'z',
    'application/x-7z-compressed': '7z',
    'application/x-ace-compressed': 'ace',
    'application/x-alz-compressed': 'alz',
    'application/vnd.ms-cab-compressed': 'cab',
    'application/x-cfs-compressed': 'cfs',
    'application/x-apple-diskimage': 'dmg',
    'application/x-rar-compressed': 'rar',
    'application/x-gtar': 'tar.gz|tgz|tar.bz2|tlz',
    'application/zip': 'zip'
  }), _TYPES);
  _exports.TYPES = TYPES;
  var TYPES_MIME_LIST = (_TYPES_MIME_LIST = {}, (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.MS_OFFICE, Object.keys(TYPES.MS_OFFICE)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.LIBRE_OFFICE, Object.keys(TYPES.LIBRE_OFFICE)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.TEXT, Object.keys(TYPES.TEXT)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.MARKDOWN, Object.keys(TYPES.MARKDOWN)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.VIDEO, Object.keys(TYPES.VIDEO)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.IMAGE, Object.keys(TYPES.IMAGE)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.PDF, Object.keys(TYPES.PDF)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.ARCHIVES, Object.keys(TYPES.ARCHIVES)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.CSV, Object.keys(TYPES.CSV)), (0, _defineProperty2.default)(_TYPES_MIME_LIST, TYPE.AUDIO, Object.keys(TYPES.AUDIO)), _TYPES_MIME_LIST);
  /**
   * Reverse lookup table from TYPES_MIME_LIST
   * @example
   * {
   *  'video/webm': TYPE.VIDEO,
   * }
   */

  _exports.TYPES_MIME_LIST = TYPES_MIME_LIST;
  var MIME_TYPE_LIST = Object.keys(TYPES_MIME_LIST).reduce(function (all, type) {
    /* eslint no-param-reassign: 0 */
    var mimes = TYPES_MIME_LIST[type];
    mimes.forEach(function (mime) {
      return all[mime] = type;
    });
    return all;
  }, {});
  _exports.MIME_TYPE_LIST = MIME_TYPE_LIST;
});
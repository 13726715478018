define("ember-svg-jar/inlined/icon-file-key", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm3.436 25.364h-.028l-.51.615v1.2a.82.82 0 01-1.641 0V21.82a.82.82 0 011.64 0v1.865h.02l1.725-2.287A1 1 0 018.683 21h.544a.49.49 0 01.377.802l-1.852 2.234 2.054 3.116a.547.547 0 01-.457.848h-.462a1 1 0 01-.847-.468zM10.5 27v-5a1 1 0 011-1h2.834a.736.736 0 010 1.472h-2.218v1.342h1.61a.724.724 0 110 1.449h-1.61v1.27h2.428a.734.734 0 010 1.467H11.5a1 1 0 01-1-1zm9.956-6h.346a.548.548 0 01.473.823l-1.82 3.132v2.21a.835.835 0 01-1.67 0v-2.21l-1.813-3.139a.544.544 0 01.471-.816h.34a1 1 0 01.885.532l.945 1.787h.029l.927-1.78a1 1 0 01.887-.539zM10 6a1 1 0 010 2 4 4 0 104 4 1 1 0 012 0 6 6 0 11-6-6zm3 4h4a5 5 0 00-5-5v4a1 1 0 001 1z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-file-zip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm7.887 23.681l-2.734 3.635 2.097.014c.461.003.833.377.833.838 0 .46-.373.832-.833.832H6.73A.729.729 0 016 28.271v-.4a1 1 0 01.194-.593l2.64-3.59-1.918-.013a.84.84 0 01-.834-.84c0-.461.373-.835.834-.835h3.707c.391 0 .708.317.708.707v.373a1 1 0 01-.201.601zM12 28.05v-5.1a.95.95 0 111.9 0v5.1a.95.95 0 11-1.9 0zm3 .04V23a1 1 0 011-1h1.723c.343 0 .655.04.936.12a2.05 2.05 0 011.22.873c.134.207.233.435.295.684s.094.518.094.81c0 .517-.11.955-.332 1.316a2 2 0 01-.924.801c-.394.173-.862.26-1.404.26h-.79v1.227a.91.91 0 11-1.818 0zm1.72-2.59h.906c.385 0 .693-.086.924-.259.231-.173.347-.42.347-.741a.908.908 0 00-.329-.714c-.218-.19-.53-.286-.936-.286h-.911zM8 5c0 .556.447 1 1 1h2c.557 0 1-.448 1-1 0-.556-.447-1-1-1H9c-.557 0-1 .448-1 1zm0 3c0-.552.443-1 1-1h2c.553 0 1 .444 1 1 0 .552-.443 1-1 1H9c-.553 0-1-.444-1-1zm0 3c0-.552.443-1 1-1h2c.553 0 1 .444 1 1 0 .552-.443 1-1 1H9c-.553 0-1-.444-1-1zm0 3c0-.552.443-1 1-1h2c.553 0 1 .444 1 1 0 .552-.443 1-1 1H9c-.553 0-1-.444-1-1zm0 3c0-.552.443-1 1-1h2c.553 0 1 .444 1 1 0 .552-.443 1-1 1H9c-.553 0-1-.444-1-1z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
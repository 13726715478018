define("ember-svg-jar/inlined/icon-category-smiley", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B3C0CE\" d=\"M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-.952A9.048 9.048 0 1010 .952a9.048 9.048 0 000 18.096zm0-4.762c1.382 0 2.556-.58 3.064-1.429H6.936c.508.85 1.682 1.429 3.064 1.429zm-4.286-2.381h8.572c0 2.104-2.182 3.333-4.286 3.333-2.104 0-4.286-1.23-4.286-3.333zm.476-3.81a.952.952 0 100-1.905.952.952 0 000 1.905zm7.62 0a.952.952 0 100-1.905.952.952 0 000 1.905z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});
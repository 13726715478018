define("web-core/utils/env-acquisition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fromWellKnown = fromWellKnown;

  function fromWellKnown(wellKnown) {
    return {
      tenant: wellKnown.tenant,
      target: wellKnown.environment,
      apiHost: wellKnown['api-host'],
      oauthHost: wellKnown['oauth-host']
    };
  }
});
define("web-core/utils/truncate", ["exports", "dompurify"], function (_exports, _dompurify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncateStart = truncateStart;
  _exports.truncateEnd = truncateEnd;
  _exports.truncateTags = truncateTags;
  var OPENING_TAG = '<em>';
  var CLOSING_TAG = '</em>';
  var JOIN_STRING = '';
  var TAG_MATCH_REGEX = new RegExp("(".concat(OPENING_TAG, "|").concat(CLOSING_TAG, ")"), 'g');

  function truncateStart(str) {
    var maxChars = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
    var shortenChars = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '…';
    var shortened = str || '';

    if (typeof str === 'string' && str.length > maxChars) {
      shortened = str.substring(0, maxChars - shortenChars.length) + shortenChars;
    }

    return shortened;
  }

  function truncateEnd(str) {
    var maxChars = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 50;
    var shortenChars = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '…';
    var shortened = str || '';

    if (typeof str === 'string' && str.length > maxChars) {
      shortened = shortenChars + str.substring(str.length - maxChars + shortenChars.length, str.length);
    }

    return shortened;
  }

  function truncateTags(message) {
    var charOffset = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 20;
    var delimiter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '…';
    var joinString = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : JOIN_STRING;
    var shortened = message;

    if (typeof message === 'string') {
      /* eslint no-param-reassign: 0 */
      message = _dompurify.default.sanitize(message, {
        ALLOWED_TAGS: ['em']
      });
      var parts = message.split(TAG_MATCH_REGEX);
      var trimmedParts = [];

      if (parts.length > 1) {
        // has marked strings
        parts.forEach(function (part, index) {
          if (part === OPENING_TAG) {
            // is opening tag
            var before = index > 0 ? truncateEnd(parts[index - 1], charOffset, delimiter) : '';
            var after = index + 3 <= parts.length ? truncateStart(parts[index + 3], charOffset, delimiter) : '';
            trimmedParts.push(before + OPENING_TAG + parts[index + 1] + CLOSING_TAG + after);
          }
        });
        shortened = trimmedParts.join(joinString);
      }
    }

    return shortened;
  }
});
define("web-core/utils/file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatSize = formatSize;
  var siUnits = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  var nonSiUnits = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  /* eslint no-param-reassign: 0 */

  function formatSize(bytes) {
    var si = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (typeof bytes !== 'number') return '';
    var thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return "".concat(bytes, " B");
    }

    var units = si ? siUnits : nonSiUnits;
    var u = -1;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);

    return "".concat(bytes.toFixed(1), " ").concat(units[u]);
  }
});
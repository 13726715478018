define("web-core/helpers/fn-value", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fnValue = fnValue;
  _exports.default = void 0;

  function fnValue(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        path = _ref2[0],
        fn = _ref2[1];

    return function (value) {
      return fn(Ember.get(value, path));
    };
  }

  var _default = Ember.Helper.helper(fnValue);

  _exports.default = _default;
});
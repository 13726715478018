define("web-core/types/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isPresent = isPresent;

  // eslint-disable-next-line ember/use-ember-data-rfc-395-imports
  function isPresent(t) {
    return t !== undefined && t !== null;
  } // copied from ember-data which isn't exporting them

});
define("web-core/initializers/inflector", ["exports", "ember-inflector"], function (_exports, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  var uncountable = ['media', 'anon-signup', 'secret-signup', 'group-permissions', 'user-permissions', 'organization-permissions', 'external-meta'];

  function initialize() {
    // @ts-ignore
    uncountable.forEach(function (word) {
      return _emberInflector.default.inflector.uncountable(word);
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});
define("web-core/types/event-content/s.message.action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ActionType = void 0;
  var ActionType;
  _exports.ActionType = ActionType;

  (function (ActionType) {
    ActionType["email"] = "email";
    ActionType["textarea"] = "textarea";
    ActionType["text"] = "text";
    ActionType["boolean"] = "boolean";
    ActionType["button"] = "button";
    ActionType["select"] = "select";
    ActionType["date"] = "date";
  })(ActionType || (_exports.ActionType = ActionType = {}));
});
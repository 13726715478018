define("web-core/utils/markup-parser/lib/token/TokenKind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // NOTE: must be bitmask compatible (start at 1)

  /**
   * Enum that represents the kind of a token.
   * This means a token can be of kind Opens and Closes if Open | Closes is set as its kind
   */
  var TokenKind;

  (function (TokenKind) {
    TokenKind[TokenKind["Default"] = 1] = "Default";
    TokenKind[TokenKind["Opens"] = 2] = "Opens";
    TokenKind[TokenKind["Closes"] = 4] = "Closes";
  })(TokenKind || (TokenKind = {}));

  var _default = TokenKind;
  _exports.default = _default;
});
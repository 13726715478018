define("ember-svg-jar/inlined/icon-file-jpg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.172 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V12.828a2 2 0 00-.586-1.414l-8.828-8.828A2 2 0 0013.172 2zm-9.93-2H14c1.5 0 2.189 1.193 2.5 1.5l8 8c.315.31 1.5 1 1.5 2.5v16.801C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm11.195 10.8C13.091 10.8 12 9.725 12 8.4S13.091 6 14.438 6c1.346 0 2.437 1.075 2.437 2.4s-1.091 2.4-2.438 2.4zM7.14 9.67c.37-.809 1.116-.897 1.662-.202l2.341 2.978a1.693 1.693 0 002.305.32l.47-.337a1.51 1.51 0 012.175.409l2.254 3.525c.477.746.136 1.351-.762 1.351H5.09c-.898 0-1.327-.653-.957-1.465zM6.048 28.906c-.365 0-.719-.077-1.06-.231a1.826 1.826 0 01-.35-.219.607.607 0 01-.072-.898l.042-.045a.886.886 0 01.995-.206.836.836 0 00.332.073c.46 0 .689-.33.689-.993v-3.584a.803.803 0 111.606 0v3.542c0 .315-.024.6-.07.855-.047.254-.125.489-.232.704-.108.216-.245.396-.412.541s-.374.258-.62.34-.53.121-.848.121zm12.86.075c-.394 0-.767-.048-1.12-.145s-.674-.243-.964-.438a3.206 3.206 0 01-.754-.704 3.085 3.085 0 01-.494-.97 4 4 0 01-.176-1.208c0-.496.08-.958.237-1.386s.38-.799.67-1.114.651-.563 1.088-.744c.437-.181.92-.272 1.452-.272.508 0 1.01.105 1.505.316.19.08.407.214.653.4a.631.631 0 01.028.984l-.018.016a1 1 0 01-1.117.121c-.415-.22-.767-.33-1.056-.33-.24 0-.468.046-.681.136-.214.091-.402.22-.562.39-.161.168-.289.38-.384.636a2.414 2.414 0 00-.143.847c0 .275.042.534.126.778.085.243.204.455.359.636.154.181.348.325.58.431s.49.16.77.16a2.4 2.4 0 001.166-.305v-.773h-.63a.677.677 0 010-1.353h1.17a1 1 0 011 1v1.488a1 1 0 01-.532.884 4.06 4.06 0 01-.668.29c-.489.153-.99.23-1.506.23zm-9.508-.89V23a1 1 0 011-1h1.723c.344 0 .656.04.936.12a2.05 2.05 0 011.22.873c.134.207.233.435.296.684.062.248.093.518.093.81 0 .517-.11.955-.332 1.316a2 2 0 01-.923.801c-.395.173-.863.26-1.405.26h-.79v1.227a.91.91 0 11-1.818 0zm1.721-2.591h.905c.386 0 .694-.086.925-.259.23-.173.346-.42.346-.741a.908.908 0 00-.328-.714c-.22-.19-.531-.286-.937-.286h-.91z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
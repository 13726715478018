define("ember-svg-jar/inlined/icon-menu-open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0h24v24H0V0z\" fill=\"none\"/><path d=\"M4.217 17.188H15.46v-1.73H4.217zm0-4.323h8.648v-1.73H4.217zm0-6.053v1.73H15.46v-1.73zm15.566 8.292L16.687 12l3.096-3.104-1.22-1.22L14.24 12l4.323 4.324z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "height": "24",
      "viewBox": "0 0 24 24",
      "width": "24"
    }
  };
  _exports.default = _default;
});
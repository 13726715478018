define("ember-svg-jar/inlined/icon-category-flag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B3C0CE\" d=\"M4.066 10.001c1.24 0 2.286.311 3.808 1.053 1.657.808 2.605 1.052 4.185 1.052 1.185 0 2.186-.365 2.993-1.052.395-.337.69-.695.886-1.048v-8.43l-.034.066c-.164.294-.553.737-.852.99-.812.692-1.818 1.053-2.993 1.053-1.583 0-2.594-.28-4.457-1.184-1.454-.706-2.262-.92-3.536-.92-1.094 0-2.097.21-3 .614v.17L1.063 4.4a14478.937 14478.937 0 00-.002 6.22A7.645 7.645 0 014.065 10zm-3.004 1.79v7.683c0 .29-.238.526-.53.526A.529.529 0 010 19.474V.526C0 .236.238 0 .531 0c.293 0 .531.236.531.526v.531c.9-.34 1.903-.53 3.004-.53 1.516 0 2.492.295 4.004 1.029 1.66.806 2.536 1.077 3.989 1.077.964 0 1.713-.299 2.3-.799.205-.174.517-.524.615-.7a1.06 1.06 0 01.867-.602c.816-.075 1.145.639 1.158 1.004v8.52c-.009.18-.063.35-.152.494-.201.369-.559.84-1.103 1.302-.959.816-2.191 1.307-3.685 1.307-1.833 0-2.948-.329-4.654-1.16-1.26-.615-2.207-.945-3.34-.945-.921 0-2.033.221-3.003.737z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "20",
      "viewBox": "0 0 17 20"
    }
  };
  _exports.default = _default;
});
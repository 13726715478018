define("web-core/helpers/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preventDefault = preventDefault;
  _exports.default = void 0;

  function preventDefault() {
    return function (ev) {
      ev.preventDefault();
    };
  }

  var _default = Ember.Helper.helper(preventDefault);

  _exports.default = _default;
});
define("web-core/types/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Resolution = _exports.ReportType = void 0;
  var ReportType;
  _exports.ReportType = ReportType;

  (function (ReportType) {
    ReportType["MESSAGE_PER_LANGUAGE"] = "MESSAGE_PER_LANGUAGE";
    ReportType["MESSAGE_PER_USERTYPE"] = "MESSAGE_PER_USERTYPE";
    ReportType["MESSAGE_PER_USERTYPE_HISTOGRAM"] = "MESSAGE_PER_USERTYPE_HISTOGRAM";
    ReportType["UNANSWERED_CHATS"] = "UNANSWERED_CHATS";
    ReportType["RATING_STARS"] = "RATING_STARS";
    ReportType["RATING_STARS_AVG"] = "RATING_STARS_AVG";
    ReportType["RATING_THUMBS_UP"] = "RATING_THUMBS_UP";
    ReportType["RATING_THUMBS_DOWN"] = "RATING_THUMBS_DOWN";
    ReportType["MESSAGE_PER_ROOM"] = "MESSAGE_PER_ROOM";
    ReportType["RESPONSE_TIME_PER_OPERATOR"] = "RESPONSE_TIME_PER_OPERATOR";
    ReportType["CHAT_PER_OPERATOR"] = "CHAT_PER_OPERATOR";
    ReportType["OPERATOR_AVAILABILITY"] = "OPERATOR_AVAILABILITY";
    ReportType["TOTAL_CHATS"] = "TOTAL_CHATS";
  })(ReportType || (_exports.ReportType = ReportType = {}));

  var Resolution;
  _exports.Resolution = Resolution;

  (function (Resolution) {
    Resolution["HOURLY"] = "HOURLY";
    Resolution["DAILY"] = "DAILY";
    Resolution["WEEKLY"] = "WEEKLY";
    Resolution["MONTHLY"] = "MONTHLY";
    Resolution["YEARLY"] = "YEARLY";
  })(Resolution || (_exports.Resolution = Resolution = {}));
});
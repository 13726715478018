define("ember-svg-jar/inlined/icon-category-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B3C0CE\" fill-rule=\"evenodd\" d=\"M7.538 16.263h1.638a6.84 6.84 0 012.085-4.454l.07.073c2.115-2.272 2.026-5.87-.237-8.134-2.313-2.313-6.021-2.355-8.282-.094-2.261 2.26-2.219 5.969.094 8.282l-.039.039a7.298 7.298 0 011.988 4.288h1.607V9.684l-1.939-1.42a.519.519 0 01-.108-.738.547.547 0 01.754-.105L7 8.763l1.83-1.342a.547.547 0 01.755.105.519.519 0 01-.108.737L7.538 9.684v6.58zm.985 3.684A1.614 1.614 0 017 21c-.703 0-1.302-.44-1.523-1.053h-.63a1.065 1.065 0 01-1.078-1.052v-2.632h.016c-.169-1.415-.834-2.782-1.875-3.752l.044-.047C-.573 9.72-.552 5.494 2.05 2.892 4.722.22 9.104.27 11.838 3.004s2.784 7.116.112 9.788l-.075-.075a5.787 5.787 0 00-1.644 3.777v2.4c0 .582-.482 1.053-1.077 1.053h-.63zm.092-1.052h.539v-1.58H4.846v1.58h3.769z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "21",
      "viewBox": "0 0 14 21"
    }
  };
  _exports.default = _default;
});
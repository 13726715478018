define("ember-promise-helpers/helpers/promise-hash", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params, hash) {
      return Ember.RSVP.hash(hash);
    }
  });

  _exports.default = _default;
});
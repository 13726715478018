define("web-core/utils/markup-parser/lib/utils/Conditions", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "web-core/utils/markup-parser/lib/token/TokenKind"], function (_exports, _slicedToArray2, _TokenKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sameOpeningBefore = _exports.otherTokenAfter = _exports.otherTokenBefore = _exports.newlineBeforeWith = _exports.spaceOrNewlineBeforeWith = _exports.closes = _exports.opens = _exports.whitespaceBeforeAndAfter = _exports.whitespaceBeforeOrAfter = _exports.whitespaceAfter = _exports.whitespaceBefore = _exports.endOfString = _exports.startOfString = _exports.not = _exports.or = _exports.and = void 0;
  var WHITESPACE_DELIMITER = /[\n :.,+&?!/()]/;
  var SPACE_OR_NEWLINE_BEFORE = /^[ \n]+$/;

  var and = function and() {
    for (var _len = arguments.length, fns = new Array(_len), _key = 0; _key < _len; _key++) {
      fns[_key] = arguments[_key];
    }

    return function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }

      return fns.every(function (fn) {
        return fn.apply(void 0, args);
      });
    };
  };

  _exports.and = and;

  var or = function or() {
    for (var _len3 = arguments.length, fns = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      fns[_key3] = arguments[_key3];
    }

    return function () {
      for (var _len4 = arguments.length, args = new Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
        args[_key4] = arguments[_key4];
      }

      return fns.some(function (fn) {
        return fn.apply(void 0, args);
      });
    };
  };

  _exports.or = or;

  var not = function not(fn) {
    return function () {
      return !fn.apply(void 0, arguments);
    };
  };

  _exports.not = not;

  var startOfString = function startOfString(_, start) {
    return start === 0;
  };

  _exports.startOfString = startOfString;

  var endOfString = function endOfString(str, _, end) {
    return end === str.length;
  };

  _exports.endOfString = endOfString;

  var whitespaceBefore = function whitespaceBefore(str, start) {
    return WHITESPACE_DELIMITER.test(str[start - 1]);
  };

  _exports.whitespaceBefore = whitespaceBefore;

  var whitespaceAfter = function whitespaceAfter(str, _, end) {
    return WHITESPACE_DELIMITER.test(str[end]);
  };

  _exports.whitespaceAfter = whitespaceAfter;
  var whitespaceBeforeOrAfter = or(whitespaceBefore, whitespaceAfter, startOfString, endOfString);
  _exports.whitespaceBeforeOrAfter = whitespaceBeforeOrAfter;
  var whitespaceBeforeAndAfter = and(or(whitespaceBefore, startOfString), or(whitespaceAfter, endOfString));
  _exports.whitespaceBeforeAndAfter = whitespaceBeforeAndAfter;
  var opens = or(whitespaceBefore, startOfString);
  _exports.opens = opens;
  var closes = or(whitespaceAfter, endOfString);
  _exports.closes = closes;

  var spaceOrNewlineBeforeWith = function spaceOrNewlineBeforeWith(newlineId) {
    return function (string, _start, _end, index, tokens) {
      var _tokens$index = (0, _slicedToArray2.default)(tokens[index], 1),
          tStart = _tokens$index[0];

      var stringBefore;

      if (index - 1 >= 0) {
        // has previous token
        var _tokens = (0, _slicedToArray2.default)(tokens[index - 1], 3),
            pTEnd = _tokens[1],
            matcher = _tokens[2]; // previous token is newline


        if (matcher.id === newlineId) {
          // either newline end = current start
          return pTEnd === tStart || // or string between newline end and current start is whitespace
          SPACE_OR_NEWLINE_BEFORE.test(string.substring(pTEnd, tStart));
        }

        stringBefore = string.substring(pTEnd, tStart);
      } else {
        stringBefore = string.substring(0, tStart);
      }

      return SPACE_OR_NEWLINE_BEFORE.test(stringBefore);
    };
  };

  _exports.spaceOrNewlineBeforeWith = spaceOrNewlineBeforeWith;

  var newlineBeforeWith = function newlineBeforeWith(newlineId) {
    return function (string, _start, _end, index, tokens) {
      var _tokens$index2 = (0, _slicedToArray2.default)(tokens[index], 1),
          tStart = _tokens$index2[0];

      if (index - 1 >= 0) {
        // has previous token
        var _tokens2 = (0, _slicedToArray2.default)(tokens[index - 1], 3),
            pTEnd = _tokens2[1],
            matcher = _tokens2[2]; // previous token is newline


        if (matcher.id === newlineId) {
          // either newline end = current start
          return pTEnd === tStart || // or string between newline end and current start is whitespace
          SPACE_OR_NEWLINE_BEFORE.test(string.substring(pTEnd, tStart));
        }
      }

      return false;
    };
  };

  _exports.newlineBeforeWith = newlineBeforeWith;

  var otherTokenBefore = function otherTokenBefore(_string, start, _end, index, tokens) {
    if (index - 1 >= 0) {
      var _tokens3 = (0, _slicedToArray2.default)(tokens[index - 1], 3),
          tEnd = _tokens3[1],
          prevMatcher = _tokens3[2];

      var _tokens$index3 = (0, _slicedToArray2.default)(tokens[index], 3),
          currentMatcher = _tokens$index3[2];

      if (prevMatcher.id !== currentMatcher.id) {
        return start === tEnd;
      }
    }

    return false;
  };

  _exports.otherTokenBefore = otherTokenBefore;

  var otherTokenAfter = function otherTokenAfter(_string, _start, end, index, tokens) {
    if (index + 1 < tokens.length) {
      var _tokens4 = (0, _slicedToArray2.default)(tokens[index + 1], 3),
          tStart = _tokens4[0],
          nextMatcher = _tokens4[2];

      var _tokens$index4 = (0, _slicedToArray2.default)(tokens[index], 3),
          currentMatcher = _tokens$index4[2];

      if (nextMatcher.id !== currentMatcher.id) {
        return end === tStart;
      }
    }

    return false;
  };

  _exports.otherTokenAfter = otherTokenAfter;

  var sameOpeningBefore = function sameOpeningBefore(_string, start, _end, index, tokens, previousTokens) {
    var previousToken = previousTokens[index - 1];

    if (index - 1 >= 0 && previousToken) {
      var _tokens5 = (0, _slicedToArray2.default)(tokens[index - 1], 3),
          tEnd = _tokens5[1],
          prevMatcher = _tokens5[2];

      var _tokens$index5 = (0, _slicedToArray2.default)(tokens[index], 3),
          currentMatcher = _tokens$index5[2];

      if ( // previous is of same type
      prevMatcher.id === currentMatcher.id && // previous is opening
      previousToken.kind & _TokenKind.default.Opens) {
        // return true if previous end = this start
        return tEnd === start;
      }
    }

    return false;
  };

  _exports.sameOpeningBefore = sameOpeningBefore;
});
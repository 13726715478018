define("ember-svg-jar/inlined/icon-category-food-drink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#B3C0CE\"><path d=\"M12.5 7.98V1a.5.5 0 111 0v6.98c1.324-.113 1.94-.708 2-1.822V1a.5.5 0 111 0v5.184c-.09 1.703-1.145 2.656-3 2.798V11.5a.5.5 0 11-1 0V8.982c-1.857-.142-2.912-1.097-3-2.824V1a.5.5 0 011 0v5.132c.058 1.138.674 1.735 2 1.848zM13 12a1 1 0 00-1 1v5a1 1 0 002 0v-5a1 1 0 00-1-1zm0-1a2 2 0 012 2v5a2 2 0 11-4 0v-5a2 2 0 012-2zM3 12v6a1 1 0 002 0v-6H3zm-1-1h4v7a2 2 0 11-4 0v-7z\"/><path d=\"M5 1.337v9.418H1.016C1.178 5.97 2.524 2.871 5 1.337zM0 11.755h6V.5a.5.5 0 00-.71-.453C1.762 1.687 0 5.589 0 11.755z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "20",
      "viewBox": "0 0 17 20"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-own-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.24 8.6c.537-.52 1.07-1.024 1.596-1.533 2.378-2.287 4.762-4.575 7.14-6.862.228-.219.489-.262.727-.131.207.115.343.372.283.613a.798.798 0 01-.201.355c-1.026 1.002-2.063 1.992-3.1 2.988-1.488 1.434-2.981 2.862-4.469 4.296-.505.487-1.01.968-1.515 1.45-.31.295-.613.3-.917.005L3.233 6.4c-.19-.18-.282-.388-.206-.65a.57.57 0 01.928-.28c.315.274.608.57.912.86.739.705 1.482 1.406 2.22 2.111.044.06.093.104.153.16z\" fill=\"#90be40\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 10",
      "height": "10",
      "width": "17"
    }
  };
  _exports.default = _default;
});
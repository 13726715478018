define("web-core/helpers/fn-arg-count", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _toConsumableArray2, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.fnArgCount = fnArgCount;
  _exports.default = void 0;

  function fnArgCount(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        count = _ref2[0],
        fn = _ref2[1];

    return count === 0 ? function () {
      return fn();
    } : function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return fn.apply(void 0, (0, _toConsumableArray2.default)(args.slice(0, count)));
    };
  }

  var _default = Ember.Helper.helper(fnArgCount);

  _exports.default = _default;
});
define("web-core/initializers/paginations", ["exports", "web-core/paginations/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('pagination:event', _events.default, {
      instantiate: false
    });
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});
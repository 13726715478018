define("ember-elsewhere/templates/components/multiple-from-elsewhere", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4/ps+3Cp",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[28,[37,4],[[33,5,[\"actives\"]],[33,6]],null]],null]],null],null,[[[41,[48,[30,2]],[[[1,\"      \"],[18,2,[[30,1,[\"component\"]],[30,1,[\"outsideParams\"]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[46,[30,1,[\"component\"]],null,null,null],[1,\"\\n\"]],[]]]],[1]],null]],[]],null]],[\"source\",\"&default\"],false,[\"if\",\"initialized\",\"each\",\"-track-array\",\"get\",\"service\",\"name\",\"has-block\",\"yield\",\"component\"]]",
    "moduleName": "ember-elsewhere/templates/components/multiple-from-elsewhere.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
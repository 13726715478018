define("web-core/helpers/html-emoji", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "web-core/utils/emoji"], function (_exports, _slicedToArray2, _emoji) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.htmlEmoji = htmlEmoji;
  _exports.default = void 0;

  function htmlEmoji(_ref, hash) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        text = _ref2[0];

    return Ember.String.htmlSafe((0, _emoji.unicodeToImage)(text, hash.useCache));
  }

  var _default = Ember.Helper.helper(htmlEmoji);

  _exports.default = _default;
});
define("ember-promise-helpers/helpers/await", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    /**
     * @property valueBeforeSettled
     * @default null
     *
     * This is the value that gets returned synchronously as the helper's return
     * value before the promise is settled. For example `{{async promise}}` will return
     * null, before the promise is resolved or rejected.
     */
    valueBeforeSettled: null,

    /**
     * @method compute
     * @public
     * @param params Array a list of arguments passed to the Helper.
     * @param hash Object a list of configuration options passed to the helper.
     * This parameter is currently unused by Await.
     */
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
          maybePromise = _ref2[0];

      if (!maybePromise || typeof maybePromise.then !== 'function') {
        return maybePromise;
      }

      return this.ensureLatestPromise(maybePromise, function (promise) {
        promise.then(function (val) {
          return _this.setValue(val);
        }).catch(function () {
          return _this.setValue(null);
        });
      });
    },

    /**
     * @method ensureLatestPromise
     * @public
     * @param promise Promise the new promise coming
     * @param callback Function function to be called with a wrapped promise
     *
     * Method to set the latest promise. This gets called by `compute` (which in
     * turn gets called by `recompute`). Always calls
     * the callback so the user can call `then`, `catch`, or `finally` on the
     * promise to update the value using `setValue` later.
     */
    ensureLatestPromise: function ensureLatestPromise(promise, callback) {
      callback.call(this, Ember.RSVP.resolve(promise));
      return this.valueBeforeSettled;
    },

    /**
     * @method setValue
     * @public
     * @param value Any the value to return to the helper
     *
     * `setValue` is how you should set the value to be returned to the helper
     * in the app. It exists to prevent race conditions between promises.
     * For example, you may have two promises:
     *
     * ```javascript
     * let promise1 = new Ember.RSVP.Promise((resolve, reject) => {resolve("hello");});
     * let promise2 = new Ember.RSVP.Promise((resolve, reject) => {(resolve("goodbye")});
     * ```
     *
     * And a template:
     *
     * ```handlebars
     * {{await promise}}
     * ```
     *
     * If you set the value of `promise` to be `promise1` via your component,
     * controller, or route, when the promise resolves, it will render the value
     * of `promise1`, which is "hello".
     *
     * If you set the value of `promise` to `promise2`, you would see "goodbye".
     *
     * But what happens if `promise1` resolves asynchronously, e.g., using `Ember.run.later`?
     *
     * ```javascript
     * Ember.run.later(function() {
     *  resolve("hello");
     * }, 200);
     * ```
     *
     * Even though `promise2` already resolved with "goodbye", the template would
     * render "hello", which is not the intended behavior. So, `setValue` makes you pass
     * the promise so the internal book-keeping can ensure the last-set promise always wins.
     */
    setValue: function setValue(value) {
      if (this.get('valueBeforeSettled') !== value) {
        this.set('valueBeforeSettled', value);
        this.recompute();
      }
    }
  });

  _exports.default = _default;
});
define("ember-can/helpers/can", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    can: Ember.inject.service(),
    ability: null,
    propertyName: null,
    compute: function compute(_ref, properties) {
      var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
          abilityString = _ref2[0],
          model = _ref2[1];

      var _this$can$parse = this.can.parse(abilityString),
          abilityName = _this$can$parse.abilityName,
          propertyName = _this$can$parse.propertyName;

      var ability = this.can.abilityFor(abilityName, model, properties);
      propertyName = ability.parseProperty(propertyName);

      this._removeAbilityObserver();

      this._addAbilityObserver(ability, propertyName);

      return Ember.get(ability, propertyName);
    },
    destroy: function destroy() {
      this._removeAbilityObserver();

      return this._super.apply(this, arguments);
    },
    _addAbilityObserver: function _addAbilityObserver(ability, propertyName) {
      Ember.setProperties(this, {
        ability: ability,
        propertyName: propertyName
      });
      Ember.addObserver(this, "ability.".concat(propertyName), this, 'recompute');
    },
    _removeAbilityObserver: function _removeAbilityObserver() {
      Ember.removeObserver(this, "ability.".concat(this.propertyName), this, 'recompute');
      this.ability && this.ability.destroy();
      Ember.setProperties(this, {
        ability: null,
        propertyName: null
      });
    }
  });

  _exports.default = _default;
});
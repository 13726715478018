define("ember-route-action-helper/helpers/route-action", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/toArray", "ember-route-action-helper/-private/internals"], function (_exports, _toConsumableArray2, _toArray2, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function getCurrentInfos(router) {
    var routerLib = router._routerMicrolib || router.router;
    return {
      currentInfos: routerLib.currentRouteInfos || routerLib.currentHandlerInfos,
      mapBy: routerLib.currentRouteInfos && 'route' || 'handler'
    };
  }

  function getRoutes(router) {
    var _getCurrentInfos = getCurrentInfos(router),
        currentInfos = _getCurrentInfos.currentInfos,
        mapBy = _getCurrentInfos.mapBy;

    return Ember.A(currentInfos).mapBy(mapBy).reverse();
  }

  function getRouteWithAction(router, actionName) {
    var action;
    var handler = Ember.A(getRoutes(router)).find(function (route) {
      var actions = route.actions || route._actions;
      action = actions[actionName];
      return typeof action === 'function';
    });
    return {
      action: action,
      handler: handler
    };
  }

  var _default = Ember.Helper.extend({
    router: Ember.computed(function () {
      return Ember.getOwner(this).lookup('router:main');
    }).readOnly(),
    compute: function compute(_ref) {
      var _ref2 = (0, _toArray2.default)(_ref),
          actionName = _ref2[0],
          params = _ref2.slice(1);

      var router = Ember.get(this, 'router');
      (false && !(router) && Ember.assert('[ember-route-action-helper] Unable to lookup router', router));
      Ember.runInDebug(function () {
        var _getRouteWithAction = getRouteWithAction(router, actionName),
            handler = _getRouteWithAction.handler;

        (false && !(handler) && Ember.assert("[ember-route-action-helper] Unable to find action ".concat(actionName), handler));
      });

      var routeAction = function routeAction() {
        var _Ember$run;

        var _getRouteWithAction2 = getRouteWithAction(router, actionName),
            action = _getRouteWithAction2.action,
            handler = _getRouteWithAction2.handler;

        for (var _len = arguments.length, invocationArgs = new Array(_len), _key = 0; _key < _len; _key++) {
          invocationArgs[_key] = arguments[_key];
        }

        var args = params.concat(invocationArgs);
        return (_Ember$run = Ember.run).join.apply(_Ember$run, [handler, action].concat((0, _toConsumableArray2.default)(args)));
      };

      routeAction[_internals.ACTION] = true;
      return routeAction;
    }
  });

  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(-7 -5)\"><rect width=\"30\" height=\"30\" rx=\"5\"/><path fill=\"#B3C0CE\" fill-rule=\"nonzero\" d=\"M17.12 9.533l-1.118-1.119V19.99a1 1 0 01-2 0V8.414l-1.118 1.119a1 1 0 11-1.414-1.415l2.822-2.823a.997.997 0 01.71-.295.987.987 0 01.708.292l2.825 2.826a1 1 0 11-1.414 1.415zM21 22.999v-3a1 1 0 112 0v3A2 2 0 0121.004 25H8.996A1.996 1.996 0 017 23v-3.002a1 1 0 112 0L8.996 23H21z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20"
    }
  };
  _exports.default = _default;
});
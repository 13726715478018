define("ember-svg-jar/inlined/icon-file", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "viewBox": "0 0 26 32",
      "height": "32"
    }
  };
  _exports.default = _default;
});
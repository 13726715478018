define("ember-svg-jar/inlined/icon-download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(-7 -5)\"><rect width=\"30\" height=\"30\" rx=\"5\"/><path fill=\"#B3C0CE\" fill-rule=\"nonzero\" d=\"M12.886 16.458l1.118 1.118V6.001a1 1 0 012 0v11.575l1.118-1.118a1 1 0 111.414 1.414l-2.822 2.824a.997.997 0 01-.71.295.987.987 0 01-.707-.292l-2.826-2.827a1 1 0 111.415-1.414zM21 22.999v-3a1 1 0 112 0v3A2 2 0 0121.004 25H8.996A1.996 1.996 0 017 23v-3.002a1 1 0 112 0L8.996 23H21z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "20",
      "viewBox": "0 0 16 20"
    }
  };
  _exports.default = _default;
});
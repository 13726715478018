define("web-core/utils/markup-parser/lib/rule/Rule", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "web-core/utils/markup-parser/lib/token/TokenKind"], function (_exports, _classCallCheck2, _TokenKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var Rule = function Rule(open, close, properties, display) {
    var openKind = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _TokenKind.default.Default;
    var closesKind = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : _TokenKind.default.Default;
    var occludes = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
    var multiline = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : true;
    (0, _classCallCheck2.default)(this, Rule);
    this.open = open;
    this.close = close;
    this.properties = properties;
    this.display = display;
    this.openKind = openKind;
    this.closesKind = closesKind;
    this.occludes = occludes;
    this.multiline = multiline;
  };

  var _default = Rule;
  _exports.default = _default;
});
define("ember-promise-helpers/helpers/promise-all", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute: function compute(params) {
      var args = Array.isArray(params[0]) ? params[0] : params;
      return Ember.RSVP.all(args);
    }
  });

  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-file-png", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.172 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V12.828a2 2 0 00-.586-1.414l-8.828-8.828A2 2 0 0013.172 2zm-9.93-2H14c1.5 0 2.189 1.193 2.5 1.5l8 8c.315.31 1.5 1 1.5 2.5v16.801C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm11.195 10.8C13.091 10.8 12 9.725 12 8.4S13.091 6 14.438 6c1.346 0 2.437 1.075 2.437 2.4s-1.091 2.4-2.438 2.4zM7.14 9.67c.37-.809 1.116-.897 1.662-.202l2.341 2.978a1.693 1.693 0 002.305.32l.47-.337a1.51 1.51 0 012.175.409l2.254 3.525c.477.746.136 1.351-.762 1.351H5.09c-.898 0-1.327-.653-.957-1.465zM14.466 22c.444 0 .803.36.803.803v5.2a.829.829 0 01-.829.828h-.278a1 1 0 01-.845-.465l-2.297-3.629h-.019v3.294a.8.8 0 11-1.6 0v-5.226c0-.445.36-.805.805-.805h.252a1 1 0 01.847.468l2.34 3.73h.018v-3.395c0-.444.36-.803.803-.803zm5.45 7c-.394 0-.768-.049-1.121-.146s-.676-.243-.967-.439a3.215 3.215 0 01-.756-.706c-.213-.276-.378-.6-.496-.972s-.176-.776-.176-1.211c0-.498.08-.961.237-1.39a3.35 3.35 0 01.672-1.117c.29-.316.653-.565 1.091-.747A3.768 3.768 0 0119.856 22c.51 0 1.013.106 1.51.317.189.08.407.214.654.4a.633.633 0 01.028.987l-.02.017a1 1 0 01-1.117.122c-.416-.22-.77-.331-1.06-.331-.24 0-.469.045-.683.136a1.67 1.67 0 00-.563.39 1.84 1.84 0 00-.385.638 2.42 2.42 0 00-.144.85c0 .275.043.535.127.78.085.243.204.456.36.638.154.181.348.325.582.432.233.106.49.16.772.16.407 0 .796-.103 1.169-.306v-.775h-.632a.678.678 0 010-1.356h1.176a1 1 0 011 1v1.495a1 1 0 01-.532.884c-.251.133-.475.23-.672.292-.49.153-.993.23-1.51.23zM3.4 28.09V23a1 1 0 011-1h1.723c.344 0 .656.04.936.12s.52.194.72.342c.198.147.365.325.5.531.134.207.233.435.296.684.062.248.093.518.093.81 0 .517-.11.955-.332 1.316-.22.36-.529.628-.923.801s-.863.26-1.405.26h-.79v1.227a.91.91 0 11-1.818 0zm1.721-2.59h.905c.386 0 .694-.086.925-.259.23-.173.346-.42.346-.741a.908.908 0 00-.328-.714c-.22-.19-.531-.286-.937-.286h-.91z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
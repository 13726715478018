define("ember-svg-jar/inlined/icon-star", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M14.823 9.257L12 2 9.171 9.257 2 9.651l5.821 5.11L5.81 22 12 17.763 18.19 22l-1.761-7.237L22 9.653z\" fill=\"#b3c0ce\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24"
    }
  };
  _exports.default = _default;
});
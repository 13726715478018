define("web-core/utils/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preload = preload;
  var Promise = Ember.RSVP.Promise; // We only want one promise per src. If the same src is used repeatedly, we just return the earlier promise.

  var preloadPool = {};

  function preload(src) {
    if (preloadPool[src]) return preloadPool[src];
    var promise = new Promise(function (resolve, reject) {
      var image = new Image();
      image.crossOrigin = 'anonymous';

      image.onload = function () {
        resolve();
        if (!image) return;
        image.onload = null;
        image.onerror = null; // @ts-ignore

        image = null;
      };

      image.onerror = function () {
        // we only want to keep resolving promises
        delete preloadPool[src];
        reject();
        if (!image) return;
        image.onload = null;
        image.onerror = null; // @ts-ignore

        image = null;
      };

      image.src = src;

      if (image.complete) {
        image.onload = null;
        image.onerror = null; // @ts-ignore

        image = null;
        resolve();
      }
    });
    preloadPool[src] = promise;
    return promise;
  }
});
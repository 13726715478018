define("web-core/utils/url", ["exports", "ember-window-mock"], function (_exports, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addParams = addParams;
  _exports.open = open;
  _exports.crnkFilterValue = crnkFilterValue;
  _exports.parseURL = _exports.host = void 0;

  var _hostNameExtractor;

  var _parseUrl; // eslint-disable-next-line @typescript-eslint/prefer-optional-chain


  if (_emberWindowMock.default.URL && _emberWindowMock.default.URL.prototype && 'href' in _emberWindowMock.default.URL.prototype) {
    var urlInstance = new URL(_emberWindowMock.default.location.href);

    _hostNameExtractor = function _hostNameExtractor(url) {
      urlInstance.href = url;
      return urlInstance.hostname;
    };

    _parseUrl = function _parseUrl(url) {
      // ensure we always have something URL can parse
      // eslint-disable-next-line no-restricted-globals
      if (url[0] === '/') url = "".concat(location.origin).concat(url);
      return new URL(url);
    };
  } else {
    var a = document.createElement('a');
    var portMap = {
      'http:': '80',
      'https:': '443',
      'ftp:': '21'
    };

    _hostNameExtractor = function _hostNameExtractor(url) {
      a.href = url;
      return a.hostname;
    };

    _parseUrl = function _parseUrl(url) {
      a.href = url;
      var expectedPort = portMap[a.protocol];
      var addPortToOrigin = a.port !== expectedPort && a.port !== '';
      var pathname = a.pathname.replace(/(^\/?)/, '/');
      return {
        hash: a.hash,
        host: a.host,
        hostname: a.hostname,
        href: a.href,
        origin: a.origin || "".concat(a.protocol, "//").concat(a.hostname).concat(addPortToOrigin ? ":".concat(a.port) : ''),
        pathname: pathname,
        port: a.port,
        protocol: a.protocol,
        search: a.search
      };
    };
  }

  var host = _hostNameExtractor;
  _exports.host = host;
  var parseURL = _parseUrl;
  _exports.parseURL = parseURL;
  var DATA_PREFIX = 'data:';
  var DATA_LENGTH = DATA_PREFIX.length;

  function isDataUri(uri) {
    return uri.substring(0, DATA_LENGTH) === DATA_PREFIX;
  }

  function addParams(url, params) {
    if (typeof url !== 'string') return ''; // can't add params to data uris

    if (isDataUri(url)) return url;

    var parsed = _parseUrl(url);

    var searchParams = new URLSearchParams(parsed.search);
    Object.keys(params).forEach(function (param) {
      var value = params[param];

      if (value === null || value === undefined) {
        searchParams.set(param, '');
      } else {
        searchParams.set(param, value);
      }
    });
    var paramString = searchParams.toString();
    var search = paramString.length ? "?".concat(paramString) : '';
    return "".concat(parsed.origin).concat(parsed.pathname).concat(search);
  }

  function open(url) {
    var a = _emberWindowMock.default.document.createElement('a');

    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.hidden = true;
    a.className = 'hidden';

    _emberWindowMock.default.document.body.append(a);

    a.click();
    a.remove(); // @ts-ignore

    a = null;
  }
  /**
   * Method to escape any user provided value to ensure it's properly handled by crnk.
   * This is needed to avoid raw `null` search queries
   * @param value
   */


  function crnkFilterValue(value) {
    return "\"".concat(value, "\"");
  }
});
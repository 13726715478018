define("web-core/utils/retry", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "web-core/utils/net/fetch", "web-core/utils/tracker"], function (_exports, _createForOfIteratorHelper2, _fetch, _tracker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.networkRetry = _exports.allowErrorProgressEvent = _exports.allowFailedRequest = _exports.allow5xx = void 0;

  var allow5xx = function allow5xx(error, ttl, timeout) {
    var status; // @ts-ignore

    if (typeof error.status === 'number') {
      // TODO: We potentially don't need this.
      _tracker.default.captureException(error, {
        extra: {
          reason: 'Something used error.status in retry'
        }
      }); // @ts-ignore


      status = error.status;
    } else if ((0, _fetch.isOkFetchError)(error)) {
      status = error.response.status;
    }

    switch (status) {
      case 500:
        return [ttl - 1, timeout * 2];

      case 502:
      case 503:
      case 504:
        return [ttl, timeout * 2];

      default:
        return false;
    }
  };

  _exports.allow5xx = allow5xx;

  var allowFailedRequest = function allowFailedRequest(error, ttl, timeout) {
    return error.name === 'TypeError' ? [ttl, timeout * 2] : false;
  };

  _exports.allowFailedRequest = allowFailedRequest;

  var allowErrorProgressEvent = function allowErrorProgressEvent(error, ttl, timeout) {
    if (!(error instanceof ProgressEvent)) return false;
    return error.type === 'error' ? [ttl, timeout * 2] : false;
  };

  _exports.allowErrorProgressEvent = allowErrorProgressEvent;
  var tests = [allow5xx, allowFailedRequest, allowErrorProgressEvent];

  var networkRetry = function networkRetry(error, ttl, timeout) {
    var _iterator = (0, _createForOfIteratorHelper2.default)(tests),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var test = _step.value;
        var allow = test(error, ttl, timeout);

        if (allow) {
          return allow;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return false;
  };

  _exports.networkRetry = networkRetry;
});
define("ember-svg-jar/inlined/icon-thumb-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFDB5E\" d=\"M34.956 18.084c0 .503-.12.975-.321 1.404-1.341 4.326-7.619 4.01-16.549 4.221-1.493.035-.639 1.798-.115 5.668.341 2.517-1.282 6.382-4.01 6.382-4.498 0-.171-3.548-4.148-12.322-2.125-4.688-6.875-2.062-6.875-6.771V5.948c0-1.833.18-3.595 2.758-3.885C8.195 1.781 7.633 0 11.238 0h18.044a3.337 3.337 0 013.333 3.334c0 .762-.267 1.456-.698 2.018 1.02.571 1.72 1.649 1.72 2.899 0 .76-.266 1.454-.696 2.015 1.023.57 1.725 1.649 1.725 2.901 0 .909-.368 1.733-.961 2.336a3.311 3.311 0 011.251 2.581z\"/><path fill=\"#EE9547\" d=\"M23.02 14.751h8.604c1.17 0 2.268.626 2.866 1.633a.876.876 0 01-1.506.892 1.588 1.588 0 00-1.361-.775h-8.81c-.873 0-1.583.71-1.583 1.583s.71 1.583 1.583 1.583H28.7a.875.875 0 010 1.75h-5.888a3.337 3.337 0 01-3.333-3.333c0-1.025.475-1.932 1.205-2.544a3.32 3.32 0 01-.998-2.373c0-1.028.478-1.938 1.212-2.549a3.318 3.318 0 01.419-5.08 3.305 3.305 0 01-.852-2.204A3.337 3.337 0 0123.798.001h5.484a3.35 3.35 0 012.867 1.632.875.875 0 01-1.504.894 1.594 1.594 0 00-1.363-.776h-5.484c-.873 0-1.583.71-1.583 1.583s.71 1.583 1.583 1.583h6.506c1.17 0 2.27.626 2.867 1.633a.875.875 0 11-1.504.894 1.572 1.572 0 00-1.363-.777h-7.063a1.585 1.585 0 000 3.167h8.091a3.35 3.35 0 012.867 1.632.875.875 0 01-1.504.894 1.573 1.573 0 00-1.363-.776H23.02a1.585 1.585 0 000 3.167z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 36 36"
    }
  };
  _exports.default = _default;
});
define("web-core/services/-private/url-helpers", ["exports", "web-core/utils/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFullURL = isFullURL;
  _exports.haveSameHost = haveSameHost;

  /* eslint-env browser, node */
  var completeUrlRegex = /^(http|https)/;

  function isFullURL(url) {
    return completeUrlRegex.test(url);
  }

  function haveSameHost(a, b) {
    try {
      var urlA = (0, _url.parseURL)(a);
      var urlB = (0, _url.parseURL)(b);
      return urlA.protocol === urlB.protocol && urlA.hostname === urlB.hostname && urlA.port === urlB.port;
    } catch (_unused) {
      return false;
    }
  }
});
define("ember-elsewhere/components/multiple-from-elsewhere", ["exports", "ember-elsewhere/components/from-elsewhere", "ember-elsewhere/templates/components/multiple-from-elsewhere"], function (_exports, _fromElsewhere, _multipleFromElsewhere) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _fromElsewhere.default.extend({
    layout: _multipleFromElsewhere.default
  });

  _exports.default = _default;
});
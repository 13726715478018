define("web-core/utils/date", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.startOfDay = startOfDay;
  _exports.subDays = subDays;
  _exports.utcDate = utcDate;
  _exports.asUtcDate = asUtcDate;
  _exports.prefix = prefix;
  _exports.formatTime = formatTime;
  _exports.tzOffset = tzOffset;
  _exports.getHoursInTz = getHoursInTz;
  _exports.toMinutes = _exports.MAX_SECONDS_IN_DAY = _exports.clamp = void 0;

  function startOfDay(date) {
    var _date = new Date(date.getTime());

    _date.setHours(0, 0, 0, 0);

    return _date;
  }

  function subDays(date) {
    var n = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;

    var _date = new Date(date.getTime());

    _date.setDate(_date.getDate() - n);

    return _date;
  }
  /**
   * Converts a local date, which is intended to be a utc date to a utc date.
   * Used e.g. in a datepicker where we render the days via Date instances
   * @param date
   */


  function utcDate() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  } // welp that's the old impl


  function asUtcDate() {
    var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : new Date();
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  }

  function prefix(num) {
    var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '0';
    var minSize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 10;

    if (typeof num === 'string') {
      return num.length < 2 ? "0".concat(num) : num;
    } else {
      return num < minSize ? "".concat(prefix).concat(num) : "".concat(num);
    }
  }

  var clamp = function clamp(val, min, max) {
    return Math.max(min, Math.min(max, val));
  }; // we never reach 24:00


  _exports.clamp = clamp;
  var MAX_SECONDS_IN_DAY = 60 * 60 * 24 - 1;
  _exports.MAX_SECONDS_IN_DAY = MAX_SECONDS_IN_DAY;

  function formatTime(timeInSeconds) {
    var addSeconds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    timeInSeconds = clamp(timeInSeconds, 0, MAX_SECONDS_IN_DAY);
    var hours = Math.floor(timeInSeconds / 3600);
    var minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    var formatted = "".concat(prefix(hours), ":").concat(prefix(minutes));

    if (addSeconds) {
      var seconds = Math.floor(timeInSeconds - hours * 3600 - minutes * 60);
      formatted += ":".concat(prefix(seconds));
    }

    return formatted;
  }

  var toMinutes = function toMinutes(string) {
    var _string$split$map = string.split(':').map(function (part) {
      return Number.parseInt(part, 10);
    }),
        _string$split$map2 = (0, _slicedToArray2.default)(_string$split$map, 2),
        h = _string$split$map2[0],
        m = _string$split$map2[1];

    return h * 60 + m;
  }; // via https://github.com/mobz/get-timezone-offset


  _exports.toMinutes = toMinutes;
  var locale = 'en-US';
  var us_re = /(\d+).(\d+).(\d+),?\s+(\d+).(\d+)(.(\d+))?/;
  var format_options = {
    timeZone: 'UTC',
    hour12: false,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  var utc_f = new Intl.DateTimeFormat(locale, format_options);

  function parseDate(date_str) {
    date_str = date_str.replace(/[\u200E\u200F]/g, '');
    us_re.exec(date_str);
    return [].slice.call(us_re.exec(date_str), 1).map(function (x) {
      return Math.floor(x);
    });
  }

  function diffMinutes(d1, d2) {
    var day = d1[1] - d2[1];
    var hour = d1[3] - d2[3];
    var min = d1[4] - d2[4];
    if (day > 15) day = -1;
    if (day < -15) day = 1;
    return 60 * (24 * day + hour) + min;
  }

  function tzOffset(date, tz_str) {
    format_options.timeZone = tz_str;
    var loc_f = new Intl.DateTimeFormat(locale, format_options);
    return diffMinutes(parseDate(utc_f.format(date)), parseDate(loc_f.format(date)));
  }

  function getHoursInTz(date, tz) {
    var offset = -tzOffset(date, tz);
    var hour = date.getUTCHours();
    return (24 + (hour + Math.floor(offset / 60))) % 24;
  }
});
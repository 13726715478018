define("ember-svg-jar/inlined/icon-category-sport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B3C0CE\" d=\"M.476 10.476v-.952A9.048 9.048 0 009.524.476h.952c0 5.523-4.477 10-10 10zm19.048-.952v.952a9.048 9.048 0 00-9.048 9.048h-.952c0-5.523 4.477-10 10-10zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-.952A9.048 9.048 0 1010 .952a9.048 9.048 0 000 18.096z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-cancel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2 2l14.142 14.142M16 2L2 16\" fill=\"none\" fill-rule=\"evenodd\" stroke=\"#ABAEB0\" stroke-linecap=\"round\" stroke-width=\"3\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});
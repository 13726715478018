define("ember-svg-jar/inlined/icon-category-nature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#B3C0CE\" d=\"M18.576.585c.46.095.58.712.19.98-2.314 1.583-3.47 4.103-3.47 7.624l-.001.117-.002.092c.003 5.195-5.827 7.262-9.77 4.6-3.621-2.443-3.505-8.273.48-11.677C8.738-.015 12.938-.576 18.576.585zM6.678 3.152c-3.47 2.964-3.569 7.923-.575 9.944 3.312 2.235 8.138.525 8.135-3.697 0-.063 0-.063.002-.118l.001-.092c0-3.356.991-5.957 2.972-7.76-4.762-.802-8.267-.214-10.535 1.723zM5.275 19.459c0 .721-1.055.721-1.055 0 0-2.67-1.288-4.65-3.928-6.003a.547.547 0 01-.236-.725.522.522 0 01.707-.242c1.77.907 3.027 2.1 3.757 3.567.737-3.963 2.829-7.132 6.264-9.479a.52.52 0 01.732.15.549.549 0 01-.147.75c-4.07 2.781-6.094 6.756-6.094 11.982z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "20",
      "viewBox": "0 0 19 20"
    }
  };
  _exports.default = _default;
});
define("web-core/types/event-content/s.form.submission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FormFieldType = void 0;
  var FormFieldType;
  _exports.FormFieldType = FormFieldType;

  (function (FormFieldType) {
    FormFieldType["STARS"] = "STARS";
    FormFieldType["THUMBS"] = "THUMBS";
    FormFieldType["LABEL"] = "LABEL";
    FormFieldType["INPUT"] = "INPUT";
    FormFieldType["TEXTAREA"] = "TEXTAREA";
    FormFieldType["EMAIL"] = "EMAIL";
  })(FormFieldType || (_exports.FormFieldType = FormFieldType = {}));
});
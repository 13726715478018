define("ember-elsewhere/components/to-elsewhere", ["exports", "ember-elsewhere/templates/components/to-elsewhere"], function (_exports, _toElsewhere) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _toElsewhere.default,
    service: Ember.inject.service('ember-elsewhere'),
    tagName: '',
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('name')) {
        throw new Error("to-elsewhere takes a \"named=\" parameter, not \"name=\"");
      }

      this.get('service').show(Ember.guidFor(this), this.get('named'), this.get('send'), this.get('outsideParams'), this.get('order'));
    },
    willDestroyElement: function willDestroyElement() {
      this.get('service').clear(Ember.guidFor(this));
    }
  });

  _exports.default = _default;
});
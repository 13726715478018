define("ember-svg-jar/inlined/icon-category-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M2.85 1.429a.476.476 0 11.95 0v5.714a.476.476 0 11-.95 0V1.429zM6.182 3.81a.476.476 0 110 .95H.468a.476.476 0 110-.95h5.714zM2.375 14.76a.476.476 0 010-.951h1.9c.262 0 .475.213.475.476v4.312a.476.476 0 11-.95 0v-3.836H2.375zm.09 4.762a.476.476 0 110-.95h3.36a.476.476 0 110 .95h-3.36zm1.667-6.856c.603 0 1.092-.49 1.092-1.096a1.094 1.094 0 10-2.185 0c0 .605.49 1.096 1.093 1.096zM9.5 16.27v2.381h2.565V16.27H9.5zm3.04-.952c.263 0 .475.213.475.476v3.334a.476.476 0 01-.475.476H9.025a.476.476 0 01-.475-.476v-3.81c0-2.4 1.05-4.153 3.113-5.188a.477.477 0 01.425.852c-1.737.87-2.588 2.292-2.588 4.336h3.04zm2.66.952v2.381h2.565V16.27H15.2zm3.04-.954c.263 0 .475.213.475.476v3.332a.475.475 0 01-.475.476h-3.515a.475.475 0 01-.475-.476v-3.808c0-2.4 1.05-4.152 3.113-5.186a.476.476 0 01.425.851c-1.737.871-2.588 2.292-2.588 4.335h3.04zM14.874 4.714l1.684 1.684a.476.476 0 11-.671.672l-1.685-1.684-1.684 1.684a.476.476 0 11-.672-.672l1.685-1.684-1.685-1.684a.476.476 0 11.672-.672l1.684 1.684 1.685-1.684a.476.476 0 11.671.672l-1.684 1.684z\" fill=\"#B3C0CE\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "20",
      "viewBox": "0 0 19 20"
    }
  };
  _exports.default = _default;
});
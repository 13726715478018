define("web-core/utils/markup-parser/lib/token/TokenMatcher", ["exports", "@babel/runtime/helpers/esm/classCallCheck", "web-core/utils/markup-parser/lib/token/TokenKind"], function (_exports, _classCallCheck2, _TokenKind) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var returnTrue = function returnTrue() {
    return true;
  };

  var TokenMatcher = function TokenMatcher(regex, id) {
    var constraints = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [[returnTrue, _TokenKind.default.Default]];
    (0, _classCallCheck2.default)(this, TokenMatcher);
    this.regex = regex;
    this.id = id;
    this.constraints = constraints;
  };

  var _default = TokenMatcher;
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-file-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.172 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V12.828a2 2 0 00-.586-1.414l-8.828-8.828A2 2 0 0013.172 2zm-9.93-2H14c1.5 0 2.189 1.193 2.5 1.5l8 8c.315.31 1.5 1 1.5 2.5v16.801C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm15.415 20.8c-1.346 0-2.437-1.075-2.437-2.4s1.09-2.4 2.437-2.4c1.346 0 2.438 1.075 2.438 2.4s-1.092 2.4-2.438 2.4zm-10.62-1.644c.37-.81 1.116-.897 1.662-.203l2.341 2.979a1.693 1.693 0 002.305.32l.47-.338a1.51 1.51 0 012.175.41l2.254 3.525c.477.746.136 1.351-.762 1.351H5.988c-.898 0-1.327-.654-.957-1.465z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-angle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.001 5a.891.891 0 01-.268.638L1.566 9.736a.928.928 0 01-1.297 0 .891.891 0 010-1.275l3.519-3.46L.269 1.54a.891.891 0 010-1.277.928.928 0 011.297 0l4.167 4.098A.891.891 0 016.001 5z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 6 10",
      "height": "10",
      "width": "6"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-file-tif", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.243 0H14c1.5 0 2.189 1.193 2.5 1.5l8 8c.315.31 1.5 1 1.5 2.5v16.801C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zm9.929 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V12.828a2 2 0 00-.586-1.414l-8.828-8.828A2 2 0 0013.172 2zm1.265 8.8C13.091 10.8 12 9.725 12 8.4S13.091 6 14.438 6c1.346 0 2.437 1.075 2.437 2.4s-1.091 2.4-2.438 2.4zM7.14 9.67c.37-.809 1.116-.897 1.662-.202l2.341 2.978a1.693 1.693 0 002.305.32l.47-.337a1.51 1.51 0 012.175.409l2.254 3.525c.477.746.136 1.351-.762 1.351H5.09c-.898 0-1.327-.653-.957-1.465zm.708 18.51V23.6H6.8a.8.8 0 110-1.6h3.735a.8.8 0 110 1.6H9.488v4.58a.82.82 0 01-1.64 0zM12 28.168v-5.336a.832.832 0 011.665 0v5.336a.832.832 0 01-1.665 0zm3 .021V23a1 1 0 011-1h2.998a.736.736 0 110 1.472H16.62v1.573h1.838a.731.731 0 110 1.463h-1.838v1.681a.81.81 0 11-1.621 0z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("ember-elsewhere/templates/components/from-elsewhere", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kdK5pW7R",
    "block": "[[[41,[33,1],[[[44,[[28,[37,3],[[33,4,[\"actives\"]],[33,5]],null]],[[[41,[48,[30,3]],[[[1,\"      \"],[18,3,[[30,1,[\"lastObject\",\"component\"]],[30,1,[\"lastObject\",\"outsideParams\"]]]],[1,\"\\n\"]],[]],[[[44,[[30,1,[\"lastObject\",\"component\"]]],[[[1,\"        \"],[46,[30,2],null,null,null],[1,\"\\n\"]],[2]]]],[]]]],[1]]]],[]],null]],[\"active\",\"c\",\"&default\"],false,[\"if\",\"initialized\",\"let\",\"get\",\"service\",\"name\",\"has-block\",\"yield\",\"component\"]]",
    "moduleName": "ember-elsewhere/templates/components/from-elsewhere.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("web-core/utils/array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findLast = findLast;
  _exports.flatten = flatten;

  function findLast(array, predicate) {
    var arrayLength = Ember.get(array, 'length');

    for (var i = arrayLength - 1; i >= 0; i--) {
      var _item = array.objectAt(i);

      if (_item && predicate(_item)) {
        return _item;
      }
    }

    return undefined;
  }

  function flatten(arr) {
    return arr.reduce(function (all, i) {
      return all.concat(i);
    }, []);
  }
});
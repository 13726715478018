define("web-core/utils/markup-parser/lib/rule/RuleProperty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RuleProperty;

  (function (RuleProperty) {
    RuleProperty[RuleProperty["None"] = 0] = "None";
    RuleProperty[RuleProperty["Block"] = 1] = "Block";
  })(RuleProperty || (RuleProperty = {}));

  var _default = RuleProperty;
  _exports.default = _default;
});
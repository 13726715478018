define("ember-svg-jar/inlined/icon-angle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 6a.891.891 0 01-.638-.268L.264 1.565a.928.928 0 010-1.297.891.891 0 011.275 0L5 3.787 8.46.268a.891.891 0 011.276 0 .928.928 0 010 1.297L5.638 5.732A.891.891 0 015 6z\" fill=\"#B3C0CE\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10 6",
      "height": "6",
      "width": "10"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-other-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#90BE40\"><path d=\"M7.24 8.6c.537-.52 1.07-1.024 1.596-1.533 2.378-2.287 4.762-4.575 7.14-6.862.228-.219.489-.262.727-.131.207.115.343.372.283.613a.798.798 0 01-.201.355c-1.026 1.002-2.063 1.992-3.1 2.988-1.488 1.434-2.981 2.862-4.469 4.296-.505.487-1.01.968-1.515 1.45-.31.295-.613.3-.917.005L3.233 6.4c-.19-.18-.282-.388-.206-.65a.57.57 0 01.928-.28c.315.274.608.57.912.86.739.705 1.482 1.406 2.22 2.111.044.06.093.104.153.16z\"/><path d=\"M6 6.45c0-.2.104-.335.243-.454.789-.672 1.583-1.343 2.371-2.01l3.304-2.806a.69.69 0 01.76-.113.55.55 0 01.313.589c-.03.15-.133.263-.255.367L8.614 5.526c-.504.429-1.008.863-1.518 1.292-.389.33-.963.19-1.079-.254-.011-.046-.011-.087-.017-.113zM5 9.365c-.011.245-.113.438-.326.555-.22.117-.44.112-.63-.058-.225-.2-.44-.416-.653-.626-1.052-1.03-2.098-2.06-3.15-3.09-.197-.193-.292-.421-.213-.696.123-.439.63-.597.961-.293.327.293.63.609.945.92C2.88 7 3.824 7.93 4.77 8.854c.14.135.236.293.23.51z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17",
      "height": "10",
      "viewBox": "0 0 17 10"
    }
  };
  _exports.default = _default;
});
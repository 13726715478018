define("ember-svg-jar/inlined/icon-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M10.312 9.025l1.929 1.929a.91.91 0 11-1.287 1.287l-1.93-1.93-1.927 1.928a.91.91 0 01-1.285-1.285l1.927-1.928-1.928-1.928a.91.91 0 111.286-1.286l1.928 1.929 1.929-1.93a.91.91 0 011.285 1.286l-1.927 1.928z\" fill=\"#F4F9FC\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 18",
      "height": "18",
      "width": "18"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-file-ppt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.233 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V11.89a2 2 0 00-.662-1.486l-8.768-7.89A2 2 0 0013.233 2zm-9.99-2H14.5c.448 0 1.07.255 1.382.562l9.548 8.796c.315.31.57.927.57 1.36v18.083C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0zM10 6a1 1 0 010 2 4 4 0 104 4 1 1 0 012 0 6 6 0 11-6-6zm3 4a1 1 0 01-1-1V5a5 5 0 015 5zm4.957 18.18l.055-4.58h-1.047a.834.834 0 01-.833-.8.769.769 0 01.768-.8h3.734c.448 0 .815.353.834.8a.769.769 0 01-.769.8h-1.046l-.056 4.58a.83.83 0 01-.83.82.81.81 0 01-.81-.82zM4.5 28.09V23a1 1 0 011-1h1.723c.343 0 .655.04.936.12a2.05 2.05 0 011.22.873c.134.207.233.435.295.684.063.248.094.518.094.81 0 .517-.11.955-.332 1.316a2 2 0 01-.924.801c-.394.173-.862.26-1.404.26h-.79v1.227a.91.91 0 11-1.818 0zm1.72-2.59h.906c.385 0 .693-.086.924-.259.231-.173.347-.42.347-.741 0-.285-.11-.523-.329-.714s-.53-.286-.936-.286H6.22zm4.28 2.59V23a1 1 0 011-1h1.723c.343 0 .655.04.936.12a2.05 2.05 0 011.22.873c.134.207.233.435.295.684s.094.518.094.81c0 .517-.11.955-.332 1.316a2 2 0 01-.924.801c-.394.173-.862.26-1.404.26h-.79v1.227a.91.91 0 11-1.818 0zm1.72-2.59h.906c.385 0 .693-.086.924-.259.231-.173.347-.42.347-.741a.908.908 0 00-.329-.714c-.218-.19-.53-.286-.936-.286h-.911z\"/>",
    "attrs": {
      "height": "32",
      "viewBox": "0 0 26 32",
      "width": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});
define("web-core/types/errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Errors;

  (function (Errors) {
    Errors["SameLanguage"] = "1";
    Errors["UnsupportedLanguage"] = "2";
    Errors["UnidentifiedLanguage"] = "3";
    Errors["VirusFound"] = "4";
    Errors["EditPeriodExpired"] = "5";
    Errors["DeletedTag"] = "6";
  })(Errors || (Errors = {}));

  var _default = Errors;
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-oestereich", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8.467 3a1.5 1.5 0 110-3 1.5 1.5 0 010 3zM1.932 1.972c.227-.505.686-.56 1.022-.126l1.441 1.861c.341.439.97.527 1.419.2l.289-.21a.92.92 0 011.339.255l1.386 2.203c.294.467.084.845-.469.845H.671c-.553 0-.817-.408-.589-.916l1.85-4.112z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7"
    }
  };
  _exports.default = _default;
});
define("ember-elsewhere/services/ember-elsewhere", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super();

      this.set('actives', Ember.Object.create());
      this._alive = {};
      this._counter = 1;
    },
    show: function show(sourceId, name, component, outsideParams) {
      var order = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;

      // if current component has specific order that is greater than current internal count
      // update internal count so any subsequent component that does not provide order will
      // be after.
      if (this._counter < order) {
        this._counter = order + 1;
      }

      this._alive[sourceId] = {
        target: name || 'default',
        component: component,
        order: order || this._counter++,
        outsideParams: outsideParams
      };

      this._schedule();
    },
    clear: function clear(sourceId) {
      delete this._alive[sourceId];

      this._schedule();
    },
    _schedule: function _schedule() {
      Ember.run.scheduleOnce('afterRender', this, this._process);
    },
    _process: function _process() {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var newActives = {};
      var alive = this._alive;
      Object.keys(alive).forEach(function (sourceId) {
        var _alive$sourceId = alive[sourceId],
            target = _alive$sourceId.target,
            component = _alive$sourceId.component,
            order = _alive$sourceId.order,
            outsideParams = _alive$sourceId.outsideParams;
        newActives[target] = newActives[target] || Ember.A();
        var newActive = component ? {
          component: component,
          order: order,
          outsideParams: outsideParams
        } : null;
        newActives[target].push(newActive);
      });
      Object.keys(newActives).forEach(function (target) {
        newActives[target] = Ember.A(newActives[target].sortBy('order'));
      });
      this.set('actives', Ember.Object.create(newActives));
    }
  });

  _exports.default = _default;
});
define("web-core/types/event-content/s.message.text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.TextFormat = void 0;
  var TextFormat;
  _exports.TextFormat = TextFormat;

  (function (TextFormat) {
    TextFormat["plain"] = "text/plain";
    TextFormat["html"] = "text/html";
  })(TextFormat || (_exports.TextFormat = TextFormat = {}));
});
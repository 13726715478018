define("web-core/utils/markup-parser/lib", ["exports", "web-core/utils/markup-parser/lib/Grammar", "web-core/utils/markup-parser/lib/Node", "web-core/utils/markup-parser/lib/Parser", "web-core/utils/markup-parser/lib/Tokenizer", "web-core/utils/markup-parser/lib/rule/BlockRule", "web-core/utils/markup-parser/lib/rule/ConstantRule", "web-core/utils/markup-parser/lib/rule/Rule", "web-core/utils/markup-parser/lib/rule/RuleProperty", "web-core/utils/markup-parser/lib/rule/TextRule", "web-core/utils/markup-parser/lib/token/Token", "web-core/utils/markup-parser/lib/token/TokenKind", "web-core/utils/markup-parser/lib/token/TokenMatcher", "web-core/utils/markup-parser/lib/utils/Conditions"], function (_exports, _Grammar, _Node, _Parser, _Tokenizer, _BlockRule, _ConstantRule, _Rule, _RuleProperty, _TextRule, _Token, _TokenKind, _TokenMatcher, Conditions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Grammar", {
    enumerable: true,
    get: function get() {
      return _Grammar.default;
    }
  });
  Object.defineProperty(_exports, "Node", {
    enumerable: true,
    get: function get() {
      return _Node.default;
    }
  });
  Object.defineProperty(_exports, "Parser", {
    enumerable: true,
    get: function get() {
      return _Parser.default;
    }
  });
  Object.defineProperty(_exports, "Tokenizer", {
    enumerable: true,
    get: function get() {
      return _Tokenizer.default;
    }
  });
  Object.defineProperty(_exports, "BlockRule", {
    enumerable: true,
    get: function get() {
      return _BlockRule.default;
    }
  });
  Object.defineProperty(_exports, "ConstantRule", {
    enumerable: true,
    get: function get() {
      return _ConstantRule.default;
    }
  });
  Object.defineProperty(_exports, "Rule", {
    enumerable: true,
    get: function get() {
      return _Rule.default;
    }
  });
  Object.defineProperty(_exports, "RuleProperty", {
    enumerable: true,
    get: function get() {
      return _RuleProperty.default;
    }
  });
  Object.defineProperty(_exports, "TextRule", {
    enumerable: true,
    get: function get() {
      return _TextRule.default;
    }
  });
  Object.defineProperty(_exports, "Token", {
    enumerable: true,
    get: function get() {
      return _Token.default;
    }
  });
  Object.defineProperty(_exports, "TokenKind", {
    enumerable: true,
    get: function get() {
      return _TokenKind.default;
    }
  });
  Object.defineProperty(_exports, "TokenMatcher", {
    enumerable: true,
    get: function get() {
      return _TokenMatcher.default;
    }
  });
  _exports.Conditions = void 0;
  _exports.Conditions = Conditions;
});
define("web-core/helpers/truncate", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "web-core/utils/truncate"], function (_exports, _slicedToArray2, _truncate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.truncate = truncate;
  _exports.default = void 0;

  function truncate(_ref, hash) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
        string = _ref2[0];

    var maxChars = typeof hash.maxChars === 'number' ? hash.maxChars : 300;
    return (0, _truncate.truncateStart)(string, maxChars);
  }

  var _default = Ember.Helper.helper(truncate);

  _exports.default = _default;
});
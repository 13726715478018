define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["de", {
    "_id": "de",
    "actions": {
      "choose_option": "Bitte Auswahl treffen",
      "confirm": "Bestätigen",
      "download": "Herunterladen",
      "error": "Es ist ein Fehler aufgetreten. Bitte Eingabe überprüfen und erneut versuchen."
    },
    "calendar": {
      "days": {
        "long": {
          "fri": "Freitag",
          "mon": "Montag",
          "sat": "Samstag",
          "sun": "Sonntag",
          "thu": "Donnerstag",
          "tue": "Dienstag",
          "wed": "Mittwoch"
        },
        "short": {
          "fri": "Fr",
          "mon": "Mo",
          "sat": "Sa",
          "sun": "So",
          "thu": "Do",
          "tue": "Di",
          "wed": "Mi"
        }
      },
      "months": {
        "apr": "April",
        "aug": "August",
        "dec": "Dezember",
        "feb": "Februar",
        "jan": "Januar",
        "jul": "Juli",
        "jun": "Juni",
        "mar": "März",
        "may": "Mai",
        "nov": "November",
        "oct": "Oktober",
        "sep": "September"
      }
    },
    "common": {
      "edited": "editiert",
      "file": "Datei",
      "submit": "Abschicken"
    },
    "documents": {
      "upload": {
        "title": "Datei hochladen"
      }
    },
    "edit_event": {
      "date": "Zuletzt editiert {date}"
    },
    "emoji": {
      "group": {
        "activities": "Aktivitäten",
        "animals-nature": "Tiere & Natur",
        "flags": "Flaggen",
        "food-drink": "Essen & Trinken",
        "objects": "Objekte",
        "smileys-emotion": "Smileys & Menschen",
        "symbols": "Symbole",
        "travel-places": "Reisen & Plätze"
      },
      "insert": "Emoji einfügen"
    },
    "event": {
      "other": {
        "s": {
          "room": {
            "close": "Das Gespräch wurde beendet."
          },
          "support": {
            "request": "Supportanfrage gestellt."
          }
        }
      },
      "room": {
        "close": {
          "text": "Das Gespräch wurde beendet."
        },
        "create": {
          "text": "{name} hat das Gespräch gestartet."
        },
        "membership": {
          "text": "{name} {membership, select, join {steht zur Verfügung} leave {hat das Gespräch verlassen}}."
        }
      }
    },
    "flash": {
      "reject_media": {
        "message": "Die ausgewählte Datei kann nicht hochgeladen werden. Stelle sicher dass sie kleiner als {size} ist."
      },
      "upload_failed": {
        "title": "Upload fehlgeschlagen, bitte versuchen Sie es erneut."
      }
    },
    "media": {
      "label": "Füge auf Wunsch ein Kommentar hinzu",
      "loading": "Vorschau wird geladen...",
      "share_with": "Datei teilen mit",
      "view": "Vorschau anschauen",
      "viewer": {
        "download": "Datei herunterladen"
      }
    },
    "modes": {
      "request_agent": "Agent anfragen",
      "reset_menu": "Zurück zum Menü"
    },
    "note": {
      "formSend": {
        "description": "Feedback wurde übermittelt",
        "title": "Vielen Dank"
      },
      "iconText": {
        "description": "Gespräch mit uns starten",
        "title": "Willkommen"
      },
      "invalidated": {
        "description": "Ihre Sitzung ist abgelaufen. Bitte starten Sie ein neues Gespräch.",
        "title": "Es tut uns leid."
      },
      "offlineFormSend": {
        "description": "Wir haben Ihre Nachricht erhalten."
      },
      "replaced_membership": "Berater {name} hat das Gespräch übernommen",
      "roomClosed": {
        "description": "Das Gespräch wurde beendet. Ein neues Gespräch kann per Klick auf das Chat-Symbol gestartet werden.",
        "title": "Vielen Dank für das Gespräch"
      }
    },
    "rejection": {
      "NO_OPERATOR_AVAILABLE": "Kein Mitarbeiter verfügbar.",
      "OUTSIDE_BUSINESS_HOURS": "Wir haben geschlossen.",
      "SERVER_ERROR": "Es kam zu einem Serverfehler.",
      "title": "Es tut uns leid, deine Anfrage konnte nicht beantwortet werden."
    },
    "reply": {
      "action": "Antworten",
      "close": "Antwort abbrechen",
      "me": "Ich"
    },
    "room": {
      "boundary": "Vorheriges Gespräch (vom {date})",
      "empty": {
        "description": "Hilfe bei Fragen gibt es im Chat!",
        "title": "Hallo!"
      },
      "placeholder": "Nachricht verfassen...",
      "send": "Nachricht abschicken",
      "typing": "{name} tippt gerade..."
    },
    "room_event": {
      "read": "Nachricht gelesen",
      "redacted": "Nachricht wurde gelöscht",
      "redacted_at": "Gelöscht am {date}",
      "saved": "Nachricht empfangen"
    }
  }], ["en", {
    "_id": "en",
    "actions": {
      "choose_option": "Please select",
      "confirm": "Confirm",
      "download": "Download",
      "error": "An error has occurred. Please check the input and try again."
    },
    "calendar": {
      "days": {
        "long": {
          "fri": "Friday",
          "mon": "Monday",
          "sat": "Satursday",
          "sun": "Sunday",
          "thu": "Thursday",
          "tue": "Tuesday",
          "wed": "Wednesday"
        },
        "short": {
          "fri": "Fr",
          "mon": "Mo",
          "sat": "Sa",
          "sun": "Su",
          "thu": "Th",
          "tue": "Tu",
          "wed": "We"
        }
      },
      "months": {
        "apr": "April",
        "aug": "August",
        "dec": "December",
        "feb": "February",
        "jan": "January",
        "jul": "July",
        "jun": "June",
        "mar": "March",
        "may": "May",
        "nov": "November",
        "oct": "October",
        "sep": "September"
      }
    },
    "common": {
      "edited": "edited",
      "file": "File",
      "submit": "Submit"
    },
    "documents": {
      "upload": {
        "title": "Upload file"
      }
    },
    "edit_event": {
      "date": "Last edited {date}"
    },
    "emoji": {
      "group": {
        "activities": "Activities",
        "animals-nature": "Animals & Nature",
        "flags": "Flags",
        "food-drink": "Food & Drink",
        "objects": "Objects",
        "smileys-emotion": "Smileys & People",
        "symbols": "Symbols",
        "travel-places": "Travel & Places"
      },
      "insert": "Insert Emoji"
    },
    "event": {
      "other": {
        "s": {
          "room": {
            "close": "Room was closed."
          },
          "support": {
            "request": "Support inquiry submitted."
          }
        }
      },
      "room": {
        "close": {
          "text": "The conversation was closed."
        },
        "create": {
          "text": "{name} has started the conversation."
        },
        "membership": {
          "text": "{name} {membership, select, join {is available} leave {has left the conversation}}."
        }
      }
    },
    "flash": {
      "reject_media": {
        "message": "The selected file cannot be uploaded. Make sure that it is smaller than {size}."
      },
      "upload_failed": {
        "title": "Upload failed, please try again."
      }
    },
    "media": {
      "label": "Add a comment if desired",
      "loading": "Loading preview...",
      "share_with": "Share file with",
      "view": "Open preview",
      "viewer": {
        "download": "Download file"
      }
    },
    "modes": {
      "request_agent": "Request agent",
      "reset_menu": "Back to the menu"
    },
    "note": {
      "formSend": {
        "description": "Feedback has been submitted",
        "title": "Thank you"
      },
      "iconText": {
        "description": "Start a conversation with us",
        "title": "Welcome"
      },
      "invalidated": {
        "description": "Your session has expired. Please start a new conversation.",
        "title": "We are sorry."
      },
      "offlineFormSend": {
        "description": "We have received your message."
      },
      "replaced_membership": "Consultant {name} has taken over the conversation",
      "roomClosed": {
        "description": "The conversation has ended. A new conversation can be started by clicking on the chat icon.",
        "title": "Thanks for the conversation"
      }
    },
    "rejection": {
      "NO_OPERATOR_AVAILABLE": "No employee available.",
      "OUTSIDE_BUSINESS_HOURS": "We are closed.",
      "SERVER_ERROR": "A server error occurred.",
      "title": "We are sorry, your request could not be answered."
    },
    "reply": {
      "action": "Reply",
      "close": "Cancel reply",
      "me": "Me"
    },
    "room": {
      "boundary": "Previous conversation (from {date})",
      "empty": {
        "description": "Do you have any questions? Use this chat to get help from us",
        "title": "Hello!"
      },
      "placeholder": "Compose your message...",
      "send": "Send message",
      "typing": "{name} is typing..."
    },
    "room_event": {
      "read": "Message read",
      "redacted": "Message has been deleted",
      "redacted_at": "Deleted on {date}",
      "saved": "Message received"
    }
  }], ["fr", {
    "_id": "fr",
    "actions": {
      "choose_option": "Veuillez sélectionner",
      "confirm": "Confirmer",
      "download": "Télécharger",
      "error": "Une erreur s'est produite. Veuillez vérifier votre saisie et réessayer."
    },
    "calendar": {
      "days": {
        "long": {
          "fri": "Vendredi",
          "mon": "Lundi",
          "sat": "Samedi",
          "sun": "Dimanche",
          "thu": "Jeudi",
          "tue": "Mardi",
          "wed": "Mercredi"
        },
        "short": {
          "fri": "Ve",
          "mon": "Lu",
          "sat": "Sa",
          "sun": "Di",
          "thu": "Je",
          "tue": "Ma",
          "wed": "Me"
        }
      },
      "months": {
        "apr": "Avril",
        "aug": "Août",
        "dec": "Décembre",
        "feb": "Février",
        "jan": "Janvier",
        "jul": "Juillet",
        "jun": "Juin",
        "mar": "Mars",
        "may": "Mai",
        "nov": "Novembre",
        "oct": "Octobre",
        "sep": "Septembre"
      }
    },
    "common": {
      "edited": "édité",
      "file": "Fichier",
      "submit": "Soumettre"
    },
    "documents": {
      "upload": {
        "title": "Charger le fichier"
      }
    },
    "edit_event": {
      "date": "Dernière édition {date}"
    },
    "emoji": {
      "group": {
        "activities": "Activités",
        "animals-nature": "Animaux & Nature",
        "flags": "Drapeaux",
        "food-drink": "Manger & Boire",
        "objects": "Objets",
        "smileys-emotion": "Smileys & Personnes",
        "symbols": "Symboles",
        "travel-places": "Voyages & Lieux"
      },
      "insert": "Ajouter Emoji"
    },
    "event": {
      "other": {
        "s": {
          "room": {
            "close": "La discussion est terminée."
          },
          "support": {
            "request": "Demande d’assistance émise."
          }
        }
      },
      "room": {
        "close": {
          "text": "La discussion est terminée."
        },
        "create": {
          "text": "{name} a démarré la discussion."
        },
        "membership": {
          "text": "{name} {membership, select, join {est disponible} leave {a quitté la conversation}}."
        }
      }
    },
    "flash": {
      "reject_media": {
        "message": "Le fichier sélectionné ne peut pas être chargé. Vérifiez qu’il est inférieur à {size} ."
      },
      "upload_failed": {
        "title": "Échec de chargement, veuillez réessayer."
      }
    },
    "media": {
      "label": "Ajoute un commentaire si nécessaire",
      "loading": "L’aperçu est chargé...",
      "share_with": "Partager le fichier avec",
      "view": "Consulter l’aperçu",
      "viewer": {
        "download": "Télécharger le fichier"
      }
    },
    "modes": {
      "request_agent": "Demander agent",
      "reset_menu": "Retour au menu"
    },
    "note": {
      "formSend": {
        "description": "Un feedback été transmis",
        "title": "Merci"
      },
      "iconText": {
        "description": "Démarrer la discussion avec nous",
        "title": "Bienvenue"
      },
      "invalidated": {
        "description": "Votre session a expiré. Veuillez démarrer une nouvelle discussion.",
        "title": "Nous sommes désolés."
      },
      "offlineFormSend": {
        "description": "Nous avons reçu votre message."
      },
      "replaced_membership": "Le conseiller {name} a pris en charge la discussion",
      "roomClosed": {
        "description": "La discussion est terminée. Une nouvelle discussion peut être démarrée en cliquant sur le symbole Chat.",
        "title": "Merci pour la discussion"
      }
    },
    "rejection": {
      "NO_OPERATOR_AVAILABLE": "Aucun opérateur disponible.",
      "OUTSIDE_BUSINESS_HOURS": "Nous sommes fermés.",
      "SERVER_ERROR": "Une erreur de serveur est survenue.",
      "title": "Nous sommes désolés de ne pas pouvoir répondre à votre demande."
    },
    "reply": {
      "action": "Répondre",
      "close": "Annuler réponse",
      "me": "Moi"
    },
    "room": {
      "boundary": "Conversation précédente (du {date})",
      "empty": {
        "description": "Vous trouverez des réponses à vos questions dans la fenêtre de chat !",
        "title": "Bonjour !"
      },
      "placeholder": "Rédiger message...",
      "send": "Envoyer message",
      "typing": "{name} est en train de taper..."
    },
    "room_event": {
      "read": "Message lu",
      "redacted": "Le message a été supprimé",
      "redacted_at": "Supprimé le {date}",
      "saved": "Message reçu"
    }
  }], ["it", {
    "_id": "it",
    "actions": {
      "choose_option": "Selezionare",
      "confirm": "Conferma",
      "download": "Scarica",
      "error": "Si è verificato un errore. Si prega di controllare i dati inseriti e riprovare."
    },
    "calendar": {
      "days": {
        "long": {
          "fri": "Venerdì",
          "mon": "Lunedì",
          "sat": "Saturno",
          "sun": "Domenicale",
          "thu": "Giovedì",
          "tue": "Martedì",
          "wed": "Mercoledì"
        },
        "short": {
          "fri": "Ve",
          "mon": "Lu",
          "sat": "Sa",
          "sun": "Do",
          "thu": "Gi",
          "tue": "Ma",
          "wed": "Me"
        }
      },
      "months": {
        "apr": "Aprile",
        "aug": "Agosto",
        "dec": "Dicembre",
        "feb": "Febbraio",
        "jan": "Gennaio",
        "jul": "Luglio",
        "jun": "Giugno",
        "mar": "Marzo",
        "may": "Maggio",
        "nov": "Novembre",
        "oct": "Ottobre",
        "sep": "Settembre"
      }
    },
    "common": {
      "edited": "modificato",
      "file": "File",
      "submit": "Invia"
    },
    "documents": {
      "upload": {
        "title": "Carica file"
      }
    },
    "edit_event": {
      "date": "Ultima modifica {date}"
    },
    "emoji": {
      "group": {
        "activities": "Attività",
        "animals-nature": "Animali e natura",
        "flags": "Bandiere",
        "food-drink": "Cibi e bevande",
        "objects": "Oggetti",
        "smileys-emotion": "Smiley e persone",
        "symbols": "Simboli",
        "travel-places": "Viaggi e luoghi"
      },
      "insert": "Aggiungi emoticon"
    },
    "event": {
      "other": {
        "s": {
          "room": {
            "close": "La conversazione è stata terminata."
          },
          "support": {
            "request": "Richiesta di supporto inoltrata."
          }
        }
      },
      "room": {
        "close": {
          "text": "La conversazione è stata terminata."
        },
        "create": {
          "text": "{name} ha avviato la conversazione."
        },
        "membership": {
          "text": "{name} {membership, select, join {è disponibile} leave {ha abbandonato la conversazione}}."
        }
      }
    },
    "flash": {
      "reject_media": {
        "message": "Impossibile caricare il file selezionato. Verificare che sia più piccolo di {size} ."
      },
      "upload_failed": {
        "title": "Caricamento non riuscito, riprovare."
      }
    },
    "media": {
      "label": "Aggiungi un commento, se necessario",
      "loading": "Caricamento anteprima in corso...",
      "share_with": "Condividi il file con",
      "view": "Mostra anteprima",
      "viewer": {
        "download": "Scarica file"
      }
    },
    "modes": {
      "request_agent": "Richiedi agente",
      "reset_menu": "Torna al menu"
    },
    "note": {
      "formSend": {
        "description": "Il feedback è stato inoltrato",
        "title": "Grazie"
      },
      "iconText": {
        "description": "Avvia una conversazione con noi",
        "title": "Benvenuto"
      },
      "invalidated": {
        "description": "La sessione è scaduta. Avviare una nuova conversazione.",
        "title": "Ci dispiace."
      },
      "offlineFormSend": {
        "description": "Abbiamo ricevuto il messaggio."
      },
      "replaced_membership": "Il consulente {name} ha accettato la conversazione",
      "roomClosed": {
        "description": "La conversazione è stata terminata. È possibile avviare una nuova conversazione facendo clic sul simbolo della chat.",
        "title": "Grazie per la conversazione"
      }
    },
    "rejection": {
      "NO_OPERATOR_AVAILABLE": "Nessun collaboratore disponibile.",
      "OUTSIDE_BUSINESS_HOURS": "Siamo chiusi.",
      "SERVER_ERROR": "Si è verificato un errore del server.",
      "title": "Purtroppo non è stato possibile rispondere alla richiesta."
    },
    "reply": {
      "action": "Rispondi",
      "close": "Annulla risposta",
      "me": "Io"
    },
    "room": {
      "boundary": "Conversazione precedente (del {date})",
      "empty": {
        "description": "In caso di domande è disponibile l'assistenza via chat!",
        "title": "Ciao!"
      },
      "placeholder": "Scrivi messaggio...",
      "send": "Invia messaggio",
      "typing": "{name} sta digitando..."
    },
    "room_event": {
      "read": "Messaggio letto",
      "redacted": "Il messaggio è stato cancellato",
      "redacted_at": "Cancellato in data {date}",
      "saved": "Messaggio ricevuto"
    }
  }], ["nl", {
    "_id": "nl",
    "actions": {
      "choose_option": "Maak een keuze",
      "confirm": "Bevestig",
      "download": "Downloaden",
      "error": "Er is een fout opgetreden. Controleer de invoer en probeer het opnieuw."
    },
    "calendar": {
      "days": {
        "long": {
          "fri": "vrijdag",
          "mon": "maandag",
          "sat": "zaterdag",
          "sun": "zondag",
          "thu": "donderdag",
          "tue": "dinsdag",
          "wed": "woensdag"
        },
        "short": {
          "fri": "vr",
          "mon": "ma",
          "sat": "za",
          "sun": "zo",
          "thu": "do",
          "tue": "di",
          "wed": "wo"
        }
      },
      "months": {
        "apr": "april",
        "aug": "augustus",
        "dec": "cecember",
        "feb": "februari",
        "jan": "januari",
        "jul": "juli",
        "jun": "juni",
        "mar": "maart",
        "may": "mei",
        "nov": "november",
        "oct": "oktober",
        "sep": "september"
      }
    },
    "common": {
      "edited": "bewerkt",
      "file": "Bestand",
      "submit": "Verzenden"
    },
    "documents": {
      "upload": {
        "title": "Bestand uploaden"
      }
    },
    "edit_event": {
      "date": "Voor het laatst bewerkt {date}"
    },
    "emoji": {
      "group": {
        "activities": "Activiteiten",
        "animals-nature": "Dieren & natuur",
        "flags": "Vlaggen",
        "food-drink": "Eten & drinken",
        "objects": "Objecten",
        "smileys-emotion": "Smileys & mensen",
        "symbols": "Symbolen",
        "travel-places": "Reizen & plaatsen"
      },
      "insert": "Emoji invoegen"
    },
    "event": {
      "other": {
        "s": {
          "room": {
            "close": "Het gesprek is beëindigd."
          },
          "support": {
            "request": "Supportaanvraag gedaan."
          }
        }
      },
      "room": {
        "close": {
          "text": "Het gesprek is beëindigd."
        },
        "create": {
          "text": "{name} heeft het gesprek gestart."
        },
        "membership": {
          "text": "{name} {membership, select, join {is beschikbaar} leave {heeft het gesprek verlaten}}."
        }
      }
    },
    "flash": {
      "reject_media": {
        "message": "Het geselecteerde bestand kan niet geüpload worden. Zorg ervoor dat het kleiner is dan {size} ."
      },
      "upload_failed": {
        "title": "Uploaden mislukt, probeer het opnieuw."
      }
    },
    "media": {
      "label": "Voeg desgewenst commentaar toe",
      "loading": "Thumbnail wordt geladen...",
      "share_with": "Bestand delen met",
      "view": "Thumbnail bekijken",
      "viewer": {
        "download": "Bestand downloaden"
      }
    },
    "modes": {
      "request_agent": "Agent aanvragen",
      "reset_menu": "Terug naar het menu"
    },
    "note": {
      "formSend": {
        "description": "Feedback is doorgegeven",
        "title": "Hartelijk dank"
      },
      "iconText": {
        "description": "Gesprek met ons starten",
        "title": "Welkom"
      },
      "invalidated": {
        "description": "Uw sessie is afgelopen. Start a.u.b. een nieuw gesprek.",
        "title": "Het spijt ons."
      },
      "offlineFormSend": {
        "description": "Wij hebben uw bericht ontvangen."
      },
      "replaced_membership": "Adviseur {name} heeft het gesprek overgenomen",
      "roomClosed": {
        "description": "Het gesprek is beëindigd. Een nieuw gesprek kan worden gestart door op het chat-symbool te klikken.",
        "title": "Hartelijk dank voor het gesprek"
      }
    },
    "rejection": {
      "NO_OPERATOR_AVAILABLE": "Geen medewerker beschikbaar.",
      "OUTSIDE_BUSINESS_HOURS": "We zijn gesloten.",
      "SERVER_ERROR": "Er is een serverfout opgetreden.",
      "title": "Het spijt ons, maar je aanvraag kon niet worden beantwoord."
    },
    "reply": {
      "action": "Antwoorden",
      "close": "Antwoord annuleren",
      "me": "Ik"
    },
    "room": {
      "boundary": "Eerder gesprek (van {date})",
      "empty": {
        "description": "Hulp bij vragen is in de chat beschikbaar!",
        "title": "Hallo!"
      },
      "placeholder": "Bericht opstellen...",
      "send": "Bericht verzenden",
      "typing": "{name} is aan het typen..."
    },
    "room_event": {
      "read": "Bericht gelezen",
      "redacted": "Bericht is gewist",
      "redacted_at": "Gewist op {date}",
      "saved": "Bericht ontvangen"
    }
  }]];
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-dots-vertical", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#B3C0CE\" fill-rule=\"evenodd\"><circle cx=\"2\" cy=\"2\" r=\"2\"/><circle cx=\"2\" cy=\"9\" r=\"2\"/><circle cx=\"2\" cy=\"16\" r=\"2\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "4",
      "height": "18",
      "viewBox": "0 0 4 18"
    }
  };
  _exports.default = _default;
});
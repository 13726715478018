define("web-core/initializers/core-polyfills", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "ember-window-mock"], function (_exports, _createForOfIteratorHelper2, _emberWindowMock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    // Fix partial Headers.prototype.forEach implementation in old FF ESR <= 45
    // TODO: when can we drop FF <= 45?
    if (_emberWindowMock.default.Headers && typeof _emberWindowMock.default.Headers.prototype.forEach !== 'function') {
      _emberWindowMock.default.Headers.prototype.forEach = function (callbackfn, thisArg) {
        var _iterator = (0, _createForOfIteratorHelper2.default)(this.keys()),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var _key = _step.value;
            callbackfn.call(thisArg, this.get(_key), _key, this);
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      };
    }
  }

  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-category-travel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#B3C0CE\"><path d=\"M5.354 15.417c.413.435.766.931 1.06 1.487l.607-2.11a.533.533 0 01.122-.215A6865.037 6865.037 0 0117.646 3.312c.196-.206.43-.76.675-1.631-1.108.179-1.784.393-1.967.586-.335.352-3.833 3.999-10.502 10.95a.493.493 0 01-.24.138l-2.21.532c.81.504 1.46 1.014 1.952 1.53zm1.625 3.157a.493.493 0 01-.953.013c-.31-.977-.77-1.783-1.38-2.425-.623-.657-1.58-1.329-2.87-2.007-.42-.22-.345-.874.113-.984l3.36-.809a8549.773 8549.773 0 0010.397-10.84c.446-.468 1.508-.766 3.294-.992.348-.044.63.293.545.65-.355 1.495-.712 2.435-1.131 2.876-.33.348-3.806 4.076-10.411 11.168l-.964 3.35z\"/><path d=\"M11.914 11.361l3.591 7.192.845-1.008-2.115-8.627-2.321 2.443zm3.07-3.598l2.4 9.79a.545.545 0 01-.109.48l-1.5 1.789a.487.487 0 01-.817-.103l-4.1-8.211a.547.547 0 01.088-.617l3.2-3.368c.274-.288.741-.154.838.24zm-5.738.442L1.933 4.866l.977-.88L11.606 5.8l-2.36 2.405zm3.488-3.194L2.867 2.953a.547.547 0 00-.476.126L.655 4.641a.487.487 0 00.132.813l8.349 3.812c.207.094.456.05.613-.11l3.255-3.317c.278-.283.128-.746-.27-.828z\"/><path d=\"M14.313 17.457a.526.526 0 01.584.876l-1.579 1.052a.526.526 0 11-.584-.876l1.58-1.052zm-1.5-3.158a.526.526 0 01.584.876l-1.579 1.052a.526.526 0 01-.584-.875l1.58-1.053zM2.707 5.417a.526.526 0 01.744.745L2.398 7.214a.526.526 0 11-.744-.744l1.053-1.053zm2.5 1.579a.526.526 0 01.744.745L4.898 8.793a.526.526 0 11-.744-.744l1.053-1.053z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});
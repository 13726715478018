define("ember-svg-jar/inlined/icon-file-audio", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M13.172 2H3a1 1 0 00-1 1v26a1 1 0 001 1h20a1 1 0 001-1V12.828a2 2 0 00-.586-1.414l-8.828-8.828A2 2 0 0013.172 2zm-9.93-2H14c1.5 0 2.189 1.193 2.5 1.5l8 8c.315.31 1.5 1 1.5 2.5v16.801C26 30.568 24.546 32 22.747 32H3.253C1.457 32 0 30.563 0 28.8V3.2C0 1.433 1.456 0 3.243 0z\"/><path d=\"M8 15v8a2.813 2.813 0 00-1.408-.387 2.817 2.817 0 100 5.635c1.556 0 2.783-1.262 2.816-2.818L10 18h5v5a2.813 2.813 0 00-1.408-.387 2.817 2.817 0 100 5.635c1.556 0 2.783-1.262 2.816-2.818L17 18v-3H8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "viewBox": "0 0 26 32",
      "height": "32"
    }
  };
  _exports.default = _default;
});
define("ember-svg-jar/inlined/icon-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5.319 12.577L1.526 8.784a1.274 1.274 0 011.802-1.802L6.22 9.906l6.052-6.084a1.274 1.274 0 011.802 1.801L7.12 12.577a1.264 1.264 0 01-1.801 0z\" fill=\"#fff\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16"
    }
  };
  _exports.default = _default;
});
define("web-core/types/event-content/s.support.request.rejected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SupportRequestRejectedReason = _exports.SupportRequestStatus = void 0;
  var SupportRequestStatus;
  _exports.SupportRequestStatus = SupportRequestStatus;

  (function (SupportRequestStatus) {
    SupportRequestStatus["AVAILABLE"] = "AVAILABLE";
    SupportRequestStatus["UNAVAILABLE"] = "UNAVAILABLE";
  })(SupportRequestStatus || (_exports.SupportRequestStatus = SupportRequestStatus = {}));

  var SupportRequestRejectedReason;
  _exports.SupportRequestRejectedReason = SupportRequestRejectedReason;

  (function (SupportRequestRejectedReason) {
    SupportRequestRejectedReason["NO_OPERATOR_AVAILABLE"] = "NO_OPERATOR_AVAILABLE";
    SupportRequestRejectedReason["SERVER_ERROR"] = "SERVER_ERROR";
    SupportRequestRejectedReason["OUTSIDE_BUSINESS_HOURS"] = "OUTSIDE_BUSINESS_HOURS";
  })(SupportRequestRejectedReason || (_exports.SupportRequestRejectedReason = SupportRequestRejectedReason = {}));
});
define("web-core/types/event-content/s.room.membership", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isMembershipInvite = isMembershipInvite;

  function isMembershipInvite(content) {
    return content.membership === 'invite';
  }
});